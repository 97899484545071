import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Button, ButtonToolbar } from 'reactstrap';
import moment from 'moment';
import renderSelectField from '../../../../../shared/components/form/Select';
import config from '../../../../../config'

// const renderFieldBudgetGroup = ({
//   input, placeholder, type, isDisabled, meta: { error },
// }) => (
//   <div className="form__form-group-input-wrap">
//     <input {...input} placeholder={placeholder} type={type} disabled={isDisabled} />
//     {<span className="form__form-group-note">Alphanumeric, spaces, and hyphons. {error}</span>}
//   </div>
// );

const EditMasterCatalogModalForm = ({
  changeShowEditModal, selectedData, setForceRefresh, budgetGroupOptions, handleSubmit, setShowEditModal,
}) => {
  const selectedSeller = useSelector(store => store.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  const [showError, setShowError] = useState('');
  const [disableButton, setDisableButton] = useState(false);

  const statusOptions = [
    { label: 'ON', value: 'ON' },
    { label: 'OFF', value: 'OFF' },
  ];

  const submitForm = (formData) => {
    setDisableButton(true);
    const { adStatus, reportingVisibility, budgetGroupDaily } = formData;

    // Build update data, sp and sd are tied to ad_status
    const updateData = [
      {
        name: 'ad_status',
        value: adStatus.value,
      },
      {
        name: 'sp_status',
        value: adStatus.value,
      },
      {
        name: 'sd_status',
        value: adStatus.value,
      },
      {
        name: 'reporting_visibility',
        value: reportingVisibility.value,
      },
      {
        name: 'budget_group',
        value: budgetGroupDaily.label,
      },
    ];

    // Build update IDs
    const updateMasterIds = [];
    const addMasterIds = [];
    if (selectedData && Array.isArray(selectedData)) {
      selectedData.forEach(selectedRow => {
        if (selectedRow.sku_master_id || selectedRow.asin_master_id) {
          if (selectedRow.catalog_type === 'seller') {
            updateMasterIds.push(selectedRow.sku_master_id);
          } else if (selectedRow.catalog_type === 'vendor') {
            updateMasterIds.push(selectedRow.asin_master_id);
          }
        } else {
          addMasterIds.push({
            accountId: selectedSeller.value, asin: selectedRow.asin, sku: selectedRow.sku, brand: selectedRow.brand, subcategory: selectedRow.subcategory, adStatus, reportingVisibility, budgetGroup: budgetGroupDaily.label, userId: user.id,
          });
        }
      });
    }

    const putData = {
      accountId: selectedSeller.value,
      accountTypeId: selectedSeller.type,
      updateData,
      updateMasterIds,
      addMasterIds,
    }

    const catalogsUrl = `${config.serverUrl}/catalogs`;
    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    const requestOptions = {
      method: 'PUT',
      headers: headerWithAuth,
      body: JSON.stringify(putData),
    };

    fetch(catalogsUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then(() => {
        changeShowEditModal();
        setForceRefresh(moment().utc());
        setDisableButton(false);
      }).catch((error) => {
        console.error(error);
        setShowError('Failed to update rows.');
        setDisableButton(false);
      });
  };

  return (
    <>
      {budgetGroupOptions && budgetGroupOptions.length > 0 && (
        <form className="form" onSubmit={handleSubmit(submitForm)}>
          <h2>Manage Items</h2>
          <div className="form__form-group">
            <br />
          </div>
          <h3>{selectedData.length} items selected for update.</h3>
          <div className="form__form-group">
            <br />
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">AD Status:</span>
            <div className="form__form-group-field">
              <Field
                name="adStatus"
                component={renderSelectField}
                type="text"
                options={statusOptions}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Reporting Status:</span>
            <div className="form__form-group-field">
              <Field
                name="reportingVisibility"
                component={renderSelectField}
                type="text"
                options={statusOptions}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label typography-message">Budget Group:</span>
            <div className="form__form-group-field priority">
              <Field
                name="budgetGroupDaily"
                component={renderSelectField}
                type="text"
                options={budgetGroupOptions}
              />
            </div>
          </div>
          {showError && (
            <div className="form__form-group-error">&nbsp;&nbsp;&nbsp;Error: {showError}</div>
          )}
          <div className="form__form-group">
            <br />
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Button color="primary" type="submit" disabled={disableButton}>
              Update Selected Rows
            </Button>
            <Button type="button" onClick={() => setShowEditModal(false)}>Cancel</Button>
          </ButtonToolbar>
        </form>
      )}
      {budgetGroupOptions && budgetGroupOptions.length === 0 && (
        <div>
          <p>Budget Groups are required before you can manage Items</p>
          <br />
          <br />
          <Button type="button" onClick={changeShowEditModal}>Ok</Button>
        </div>
      )}
    </>
  );
}

EditMasterCatalogModalForm.propTypes = {
  changeShowEditModal: PropTypes.func.isRequired,
};

const validate = (values) => {
  const error = {};
  if (values && !values.budgetGroupDaily?.value) {
    error.budgetGroupDaily = 'You must select a Budget Group';
  }

  if (values && !values.adStatus?.value) {
    error.adStatus = 'Please select an AD Status';
  }

  if (values && !values.reportingVisibility?.value) {
    error.reportingVisibility = 'Please select Reporting Visibility';
  }

  return error;
};

export default reduxForm({
  form: 'ItemEditModalForm',
  validate,
})(EditMasterCatalogModalForm);
