import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import PropTypes from 'prop-types';
import { Field, reduxForm, change } from 'redux-form';
import { Button, ButtonToolbar, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import renderSelectField from '../../../../../shared/components/form/Select';
import renderCheckBoxField from '../../../../../shared/components/form/CheckBox';
import { getCorprateUrl } from '../../../../../shared/components/domainSupport';
import Tooltip from '../../../../../shared/components/widgets/Tooltip';
import Notify from '../../../../../shared/components/widgets/Notify';
import config from '../../../../../config'

// const renderFieldBudgetGroup = ({
//   input, placeholder, type, isDisabled, meta: { touched, error },
// }) => (
//   <div className="form__form-group-input-wrap">
//     <input {...input} placeholder={placeholder} type={type} disabled={isDisabled} />
//     {<span className="form__form-group-warn">Alphanumeric, spaces, and hyphons.</span>}
//     {touched && error && <span className="form__form-group-error">{error}</span>}
//   </div>
// );

const BudgetAccountEditForm = ({ budgetAccountData, setForceRefresh, handleSubmit }) => {
  const selectedSeller = useSelector(store => store.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  // const formValues = useSelector(state => getFormValues('BudgetAccountEditForm')(state));
  const [showError, setShowError] = useState('');

  const costMetricViewOptions = [
    { label: 'PACoS', value: 'pacos' },
    { label: 'ROAS', value: 'roas' },
  ];

  const dispatch = useDispatch();
  const initDropdown = (name, value, options, position = 0) => {
    dispatch(change('BudgetAccountEditForm', name, value ? options.find(option => option.value === value) : options[position]));
  }
  const protfolioName = `${JSON.parse(selectedSeller.agency).name} - BG Account Portfolio`;

  useEffect(() => {
    dispatch(change('BudgetAccountEditForm', 'budgetAccountDailyId', budgetAccountData?.budget_account_daily_id));
    initDropdown('costMetricView', budgetAccountData?.cost_metric_view, costMetricViewOptions, 0);
    dispatch(change('BudgetAccountEditForm', 'portfolioName', `${JSON.parse(selectedSeller.agency).name} - BG Account Portfolio`));
    dispatch(change('BudgetAccountEditForm', 'termsApproval', budgetAccountData?.terms_approval || 0));
  }, []);

  const submitForm = (formData) => {
    const submitBudgetAccountDaily = {
      accountId: selectedSeller.value,
      budgetAccountDailyId: formData.budgetAccountDailyId || 0,
      costMetricView: formData.costMetricView?.value,
      portfolioName: formData.portfolioName,
      termsApproval: formData.termsApproval,
    };

    const postUrl = `${config.serverUrl}/accounts/budget-account-daily`;
    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    const requestOptions = {
      method: 'POST',
      headers: headerWithAuth,
      body: JSON.stringify(submitBudgetAccountDaily),
    };

    fetch(postUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then(() => {
        Notify.show('primary', 'Success', 'Your Budget Account setting have been updated.');
        setForceRefresh(moment().utc());
      }).catch(() => {
        setShowError('We were unable to update your Budget Account Settings');
        Notify.show('danger', 'Error', 'We were unable to update your Budget Account Settings');
      });
  };

  return (
    <Col sm={3}>
      <form className="form" onSubmit={handleSubmit(submitForm)}>
        <h3>Budget Account Settings:</h3>
        <div className="form__form-group">
          <br />
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label"><h4>Cost Metric View:</h4></span>
          <div className="form__form-group-field">
            <Field
              name="costMetricView"
              component={renderSelectField}
              type="text"
              options={costMetricViewOptions}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label typography-message">
            <h4>Default Portfolio: <Tooltip id="portfolioName" msg="Portfolio that will be used to manage launched campaigns" placement="top" /></h4>
          </span>
          <div>
            <p>Portfolio Name: {protfolioName}</p>
            <p>Portfolio ID: {budgetAccountData?.portfolio_id || 'Create during first launch'}</p>
            <br />
          </div>
        </div>
        <div className="form__form-group">
          <h4>REQUIREMENTS, ACKNOWLEDGEMENTS, TERMS, AND CONDITIONS</h4>
          <br />
          <a href={`${getCorprateUrl()}/terms-of-use`} target="blank">Terms &amp; Conditions</a>
          <br />
          <p>Ensure All Ads Created on this Platform for this Account(s) will NOT EDIT ANY Campaigns located in the Account Portfolio at any time.</p>
          <br />
          <div className="form__form-group-field">
            <Field
              name="termsApproval"
              component={renderCheckBoxField}
              label="I Approve the Terms and Conditions"
              disabled={budgetAccountData?.terms_approval === 1}
            />
          </div>
        </div>
        {showError && (
          <div className="form__form-group-error">&nbsp;&nbsp;&nbsp;Error: {showError}</div>
        )}
        <div className="form__form-group">
          <br />
        </div>
        <div className="form__form-group">
          <ButtonToolbar className="form__button-toolbar">
            <Button color="primary" type="submit">
              Submit
            </Button>
          </ButtonToolbar>
        </div>

      </form>
    </Col>
  );
}

const validate = (values) => {
  const errors = {};

  if (!values.costMetricView?.value) {
    errors.costMetricView = 'Please select the default Cost Metric';
  }

  // if (!values.portfolioName) {
  //   errors.portfolioName = 'Name field cannot be empty';
  // } else if (values && values.portfolioName.length > 255) {
  //   errors.portfolioName = 'Max length is 255 characters';
  // } else if (values.portfolioName.replace(/[^\-0-9A-Za-z ]/, '').length !== values.portfolioName.length) {
  //   errors.portfolioName = 'Only alphanumeric, spaces, and hyphons are allowed';
  // }

  if (!values.termsApproval) {
    errors.termsApproval = 'You must accept the Terms and Conditions';
  }

  return errors;
};

BudgetAccountEditForm.propTypes = {
  budgetAccountData: PropTypes.shape().isRequired,
  setForceRefresh: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'BudgetAccountEditForm',
  validate,
})(BudgetAccountEditForm);
