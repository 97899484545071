import React, { useEffect, useState } from 'react';
import { FiCameraOff } from 'react-icons/fi';

function ImageCellRenderer(params) {
  const { data } = params;
  const iconStyle = { width: 40, height: 40, opacity: 0.2, color: 'var(--secondary)' };
  const [foundImage, setFoundImage] = useState(false);
  const [product] = useState(data && (data.asin || data.asin1 || data.productId));
  const [url, setUrl] = useState(data && (data.pmd_imageUrl || data.image_url));
  const imageWidth = 75;
  const imageHeight = 75;

  useEffect(() => {
    if (url) {
      setFoundImage(true);
    } else {
      setFoundImage(false);
    }
  }, [url, setFoundImage]);

  return (
    <>
      {foundImage && data && (
        <img onError={() => setUrl(undefined)} src={url} width={imageWidth} height={imageHeight} title="Product Image" alt={product} loading="lazy" />
      )}
      {!foundImage && data && (
        <FiCameraOff className="ag-no-image-icon" style={iconStyle} alt="Image not available" />
      )}
      {!data && (
        null
      )}
    </>
  );
}

export const imageFieldColumn = {
  field: 'img',
  headerName: 'Image',
  minWidth: 90,
  maxWidth: 90,
  cellRenderer: 'imageCellRenderer',
  cellClass: 'ag-image-cell',
};

export const imageCellRendererComponent = {
  imageCellRenderer: ImageCellRenderer,
};
