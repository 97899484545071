import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import NotificationSystem from 'rc-notification';
import PropTypes from 'prop-types';
import { BasicNotification } from '../../../../../shared/components/Notification';
import config from '../../../../../config';
import TableBuilder from '../../../../../shared/components/table/TableBuilder';
import {
  currencyFormatter, formatNumberDefaultToZero, getExportPrefix, numberValueParser,
} from '../../../../../shared/components/table/functions';
import { imageFieldColumn, imageCellRendererComponent } from '../../../../../shared/components/table/ImageCellRenderer';
import { doFetch } from '../../../../../helpers';
import { LoggedIn, SellerSelect, User } from '../../../../../shared/prop-types/MainProps';
import { countryOptionsArray, countryOptionDefault } from '../../../../Onboarding/amazon/components/OnboardingConfig';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

const {
  string, shape,
} = PropTypes;

const configObject = shape({
  title: string,
  endpoint: string,
  uploadPath: string,
  key: string,
});

class AllCatalogTable extends PureComponent {
  static propTypes = {
    loggedIn: LoggedIn.isRequired,
    user: User.isRequired,
    sellerSelect: SellerSelect.isRequired,
    accountConfig: configObject.isRequired,
  };

  constructor(props) {
    super(props);

    this.parentProcessChange = this.parentProcessChange.bind(this);

    const { sellerSelect, user } = this.props;

    function getColDefs(type) {
      const sellerConfig = {
        key: 'SAID',
        skuField: [
          { field: 'sku', headerName: 'SKU' },
        ],
        customFields: [
          {
            field: 'unit_cog',
            headerName: 'Unit COG',
            editable: true,
            valueFormatter: currencyFormatter,
          },
          {
            field: 'unit_inbound',
            headerName: 'Unit Inbound',
            editable: true,
            valueFormatter: currencyFormatter,
          },
        ],
      }

      const accessControl = JSON.parse(user.access_control);

      const vendorAdditionalFields = accessControl.accountControlRole ? [] : [
        // {
        //   field: 'category_node_id',
        //   headerName: 'Cat Node ID',
        //   editable: true,
        // },
        {
          field: 'color_count',
          headerName: 'Color Count',
          valueFormatter: formatNumberDefaultToZero,
          valueParser: numberValueParser,
        },
        { field: 'binding', headerName: 'Binding' },
        // { field: 'author_artist', headerName: 'Author / Artist' },
        // { field: 'sitb_enabled', headerName: 'SITB enabled?' },
        { field: 'apparel_size', headerName: 'Apparel Size' },
        { field: 'apparel_size_width', headerName: 'Apparel Size Width' },
        // { field: 'product_group', headerName: 'Product Group' },
        { field: 'replenishment_code', headerName: 'Replenishment Code' },
        { field: 'model_style_number', headerName: 'Model / Style Number' },
        // { field: 'prep_instructions_required', headerName: 'Prep Instructions Required' },
        // { field: 'brand_code', headerName: 'Brand Code' },
        // { field: 'manufacturer_code', headerName: 'Manufacturer Code' },
        // { field: 'parent_manufacturer_code', headerName: 'Parent Manufacturer Code' },
        // { field: 'isbn_13', headerName: 'ISBN-13' },
        // { field: 'ean', headerName: 'EAN' },
        { field: 'upc', headerName: 'UPC' },
        {
          field: 'default_parent_asin',
          headerName: 'VC Parent ASIN',
          headerTooltip: 'Lastest Parent ASIN found in Vendor Central reports',
        },
        {
          field: 'default_brand',
          headerName: 'VC Brand',
          headerTooltip: 'Lastest Brand found in Vendor Central reports',
        },
        {
          field: 'default_color',
          headerName: 'VC Color',
          headerTooltip: 'Lastest Color found in Vendor Central reports',
        },
        {
          field: 'default_category',
          headerName: 'VC Category',
          headerTooltip: 'Lastest Category found in Vendor Central reports',
          valueGetter: function defualt(params) {
            return params.data.default_mfg_category || params.data.default_src_category || '';
          },
        },
        {
          field: 'default_subcategory',
          headerName: 'VC Subcategory',
          headerTooltip: 'Lastest Subcategory found in Vendor Central reports',
          valueGetter: function defualt(params) {
            return params.data.default_mfg_subcategory || params.data.default_src_subcategory || '';
          },
        },
        // {
        //   field: 'release_date.value',
        //   headerName: 'Release Date',
        //   valueFormatter: toDisplayDate,
        // },
      ];

      const vendorConfig = {
        key: 'VAID',
        skuField: [
          {
            field: 'sku',
            headerName: 'SKU',
            editable: true,
            valueGetter: function defualtIfBlankOrNull(params) {
              if (params && params.data && (params.data.sku || '') === '') {
                return params.data.sku || params.data.default_sku;
              }
              return (params && params.data && params.data.sku) ? params.data.sku : '';
            },
            cellStyle: (params) => {
              if (params && params.data && (params.data.sku || '') === '') {
                return { color: 'blue' };
              }
              return null;
            },
          },
        ],
        customFields: [
          ...vendorAdditionalFields,
        ],
      }

      const additionalFields = accessControl.accountControlRole ? [] : [
        { field: 'csaa_color', headerName: 'csaa Color' },
        { field: 'pmd_availability', headerName: 'pmd Availability' },
        { field: 'pmd_eligibilityStatus', headerName: 'pmd Eligibility Status' },
        { field: 'pmd_ineligibilityReasons', headerName: 'pmd Ineligibility Reasons' },
        { field: 'pmd_ineligibilityCodes', headerName: 'pmd Ineligibility Codes' },
        {
          field: 'list_price',
          headerName: 'List Price',
          valueFormatter: currencyFormatter,
        },
        { field: 'pmd_priceToPayAmount', headerName: 'pmd Price To Pay Amount', valueFormatter: currencyFormatter },
        { field: 'pmd_basisPriceAmount', headerName: 'pmd Basis Price Amount', valueFormatter: currencyFormatter },
        { field: 'pmd_createdDate', headerName: 'pmd Created Date' },
        { field: 'pmd_bestSellerRank', headerName: 'pmd Best Seller Rank' },
        { field: 'pmd_category', headerName: 'pmd Sub Category' },
        { field: 'pmd_sku', headerName: 'pmd SKU' },
        { field: 'pmd_title', headerName: 'pmd Title' },
        { field: 'pmd_variationList', headerName: 'pmd Variation List' },
        { field: 'pmd_brand', headerName: 'pmd Brand' },
        { field: 'csaa_brand', headerName: 'csaa Brand' },
        { field: 'csaa_manufacturer', headerName: 'csaa Manufacturer' },
        { field: 'csaa_model_number', headerName: 'csaa Model Number' },
        { field: 'csaa_part_number', headerName: 'csaa Part Number' },
        { field: 'csaa_package_quantity', headerName: 'csaa Package Quantity' },
        { field: 'csaa_size', headerName: 'csaa Size' },
        { field: 'csaa_style', headerName: 'csaa Style' },
        { field: 'csaa_marketplace_id', headerName: 'csaa Marketplace' },
        { field: 'csaa_browse_classification_display_name', headerName: 'csaa Browse Classification Display Name' },
        { field: 'csaa_browse_classification_id', headerName: 'csaa Browse Classification ID' },
        { field: 'csaa_item_classification', headerName: 'csaa Item Classification' },
        { field: 'csaa_item_name', headerName: 'csaa Item Name' },
        { field: 'csaa_website_display_group', headerName: 'csaa Website Display Group' },
        { field: 'pmd_processTs.value', headerName: 'pmd Process TS' },
        { field: 'csaa_process_ts.value', headerName: 'csaa Process TS' },
      ];

      const fieldConfig = (type === 1) ? sellerConfig : vendorConfig;

      const columnDefs = [
        imageFieldColumn,
        {
          field: 'asin',
          headerName: 'ASIN',
          cellRenderer: (params) => {
            if (params && params.data) {
              const getMarketPlace = (name) => {
                let result = countryOptionDefault.marketplace;
                countryOptionsArray.forEach((country) => {
                  if (name === country.value) {
                    result = country.marketplace;
                  }
                });
                return result;
              };
              // const { sellerSelect } = this.props;
              const { mpName } = sellerSelect;
              const marketplace = getMarketPlace(mpName);
              const url = `https://${marketplace}/dp/${params.data.asin}`;
              return <a href={url} target="_blank" rel="noopener noreferrer">{params.data.asin}</a>;
            }
            return '';
          },
        },
        ...fieldConfig.skuField,
        { field: 'product_title', headerName: 'Product Title' },
        {
          field: 'ad_phase',
          headerName: 'Ad Phase',
          editable: true,
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: ['', 'new', 'growth', 'ongoing'],
          },
        },
        {
          field: 'reporting_visibility',
          headerName: 'Reporting Visibility',
          editable: true,
          cellEditor: 'agSelectCellEditor',
          valueGetter: (value) => value && value.data && value.data.reporting_visibility && value.data.reporting_visibility.toUpperCase(),
          cellEditorParams: {
            values: ['', 'ON', 'OFF'],
          },
        },
        {
          field: 'budget_group',
          headerName: 'Budget Group',
          editable: true,
        },
        {
          field: 'ad_status',
          headerName: 'Ad Status',
          editable: true,
          cellEditor: 'agSelectCellEditor',
          valueGetter: (value) => value && value.data && value.data.ad_status && value.data.ad_status.toUpperCase(),
          cellEditorParams: {
            values: ['', 'ON', 'OFF'],
          },
        },
        {
          field: 'sp_status',
          headerName: 'SP Status',
          editable: true,
          cellEditor: 'agSelectCellEditor',
          valueGetter: (value) => value && value.data && value.data.sp_status && value.data.sp_status.toUpperCase(),
          cellEditorParams: {
            values: ['', 'ON', 'OFF'],
          },
        },
        {
          field: 'sd_status',
          headerName: 'SD Status',
          editable: true,
          cellEditor: 'agSelectCellEditor',
          valueGetter: (value) => value && value.data && value.data.sd_status && value.data.sd_status.toUpperCase(),
          cellEditorParams: {
            values: ['', 'ON', 'OFF'],
          },
        },
        {
          field: 'sb_status',
          headerName: 'SB Status',
          editable: true,
          cellEditor: 'agSelectCellEditor',
          valueGetter: (value) => value && value.data && value.data.sb_status && value.data.sb_status.toUpperCase(),
          cellEditorParams: {
            values: ['', 'ON', 'OFF'],
          },
        },
        {
          field: 'sbv_status',
          headerName: 'SBV Status',
          editable: true,
          cellEditor: 'agSelectCellEditor',
          valueGetter: (value) => value && value.data && value.data.sbv_status && value.data.sbv_status.toUpperCase(),
          cellEditorParams: {
            values: ['', 'ON', 'OFF'],
          },
        },
        {
          field: 'brand',
          headerName: 'Brand',
          editable: true,
          headerTooltip: 'ASIN Brand used in reports',
          valueGetter: function defualtIfBlankOrNull(params) {
            if (params && params.data && (params.data.brand || '') === '') {
              return params.data.pmd_brand || params.data.csaa_brand || params.data.default_brand;
            }
            return (params && params.data && params.data.brand) ? params.data.brand : '';
          },
          cellStyle: (params) => {
            if (params && params.data && (params.data.brand || '') === '') {
              return { color: 'blue' };
            }
            return null;
          },
        },
        {
          field: 'parent_asin',
          headerName: 'Parent ASIN',
          editable: true,
          headerTooltip: 'Parent ASIN value used by in for all reporting',
          valueGetter: function defualtIfBlankOrNull(params) {
            if (params && params.data && (params.data.parent_asin === '' || !params.data.parent_asin)) {
              return params.data.default_parent_asin;
            }
            return (params && params.data && params.data.parent_asin) ? params.data.parent_asin : '';
          },
          cellStyle: (params) => {
            if (params && params.data && (params.data.parent_asin || '') === '') {
              return { color: 'blue' };
            }
            return null;
          },
        },
        {
          field: 'category',
          headerName: 'Category',
          editable: true,
          headerTooltip: 'ASIN Catagory used in reports',
          valueGetter: function defualtIfBlankOrNull(params) {
            if (params && params.data && (params.data.category || '') === '') {
              return params.data.default_mfg_category || params.data.default_src_category;
            }
            return (params && params.data && params.data.category) ? params.data.category : '';
          },
          cellStyle: (params) => {
            if (params && params.data && (params.data.category || '') === '') {
              return { color: 'blue' };
            }
            return null;
          },
        },
        {
          field: 'subcategory',
          headerName: 'Subcategory',
          editable: true,
          headerTooltip: 'ASIN Subcategory used in reports',
          valueGetter: function defualtIfBlankOrNull(params) {
            if (params && params.data && (params.data.subcategory || '') === '') {
              return params.data.pmd_category || params.data.default_mfg_subcategory || params.data.default_src_subcategory;
            }
            return (params && params.data && params.data.subcategory) ? params.data.subcategory : '';
          },
          cellStyle: (params) => {
            if (params && params.data && (params.data.subcategory || '') === '') {
              return { color: 'blue' };
            }
            return null;
          },
        },
        ...fieldConfig.customFields,
        {
          field: 'segment_one',
          headerName: 'Segment 1',
          editable: true,
        },
        {
          field: 'segment_two',
          headerName: 'Segment 2',
          editable: true,
        },
        {
          field: 'segment_three',
          headerName: 'Segment 3',
          editable: true,
        },
        {
          field: 'segment_four',
          headerName: 'Segment 4',
          editable: true,
        },
        {
          field: 'segment_five',
          headerName: 'Segment 5',
          editable: true,
        },
        {
          field: 'hero',
          headerName: 'Hero',
          editable: true,
          headerTooltip: 'Unique ASIN identifier assinged (1-YES, 0-NO)',
        },
        {
          field: 'size',
          headerName: 'Size',
          editable: true,
          headerTooltip: 'ASIN Size used in reports',
          valueGetter: function defualtIfBlankOrNull(params) {
            if (params && params.data && (params.data.size || '') === '') {
              return params.data.csaa_size;
            }
            return (params && params.data && params.data.size) ? params.data.size : '';
          },
          cellStyle: (params) => {
            if (params && params.data && (params.data.size || '') === '') {
              return { color: 'blue' };
            }
            return null;
          },
        },
        {
          field: 'color',
          headerName: 'Color',
          editable: true,
          headerTooltip: 'ASIN Color used in reports',
          valueGetter: function defualtIfBlankOrNull(params) {
            if (params && params.data && (params.data.color || '') === '') {
              return params.data.csaa_color || params.data.default_color;
            }
            return (params && params.data && params.data.color) ? params.data.color : '';
          },
          cellStyle: (params) => {
            if (params && params.data && (params.data.color || '') === '') {
              return { color: 'blue' };
            }
            return null;
          },
        },
        {
          field: 'color_map',
          headerName: 'Color Map',
          editable: true,
        },
        ...additionalFields,
        {
          field: 'account_id',
          headerName: fieldConfig.key,
        },
      ];
      return columnDefs;
    }

    this.state = {
      columnDefs: getColDefs(sellerSelect.type),
      defaultColDef: {
        sortable: true,
        filter: false,
        resizable: true,
        cellStyle: (params) => {
          if (params && params.value < 0.00) {
            return { color: 'red' };
          }
          return null;
        },
      },
      frameworkComponents: {
        ...imageCellRendererComponent,
      },
      showDatePicker: false,
      showImportButton: false,
      showFooter: false,
      parentControlledLoad: true,
      getColDefs,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationLU = n);
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationRU = n);
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationTC = n);
  }

  componentWillUnmount() {
    if (notificationLU) {
      notificationLU.destroy();
    }
    if (notificationLU) {
      notificationRU.destroy();
    }
    if (notificationLU) {
      notificationTC.destroy();
    }
  }

  // eslint-disable-next-line
  show = (color, title, msg) => {
    return config.showNotification({
      notification: <BasicNotification
        color={color}
        title={title}
        message={msg}
      />,
      position: 'right-up',
      notificationLU,
      notificationRU,
      notificationTC,
    });
  };

  parentProcessChange = async (rowNode, user, sellerSelect, apiEndPoint) => {
    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    const body = {
      sku: rowNode.data.sku,
      asin: rowNode.data.asin,
      sellerAccountId: sellerSelect.value.toString(),
      unitCog: rowNode.data.unit_cog || '0',
      unitInbound: rowNode.data.unit_inbound || '0',
      brand: rowNode.data.brand,
      adPhase: rowNode.data.ad_phase,
      adStatus: rowNode.data.ad_status,
      spStatus: rowNode.data.sp_status,
      sdStatus: rowNode.data.sd_status,
      sbStatus: rowNode.data.sb_status,
      sbvStatus: rowNode.data.sbv_status,
      reportingVisibility: rowNode.data.reporting_visibility,
      segmentOne: rowNode.data.segment_one,
      segmentTwo: rowNode.data.segment_two,
      segmentThree: rowNode.data.segment_three,
      segmentFour: rowNode.data.segment_four,
      segmentFive: rowNode.data.segment_five,
      size: rowNode.data.size,
      color: rowNode.data.color,
      colorMap: rowNode.data.colorMap,
      hero: rowNode.data.hero,
      budgetGroup: rowNode.data.budget_group,
    }

    if (sellerSelect.type === 2) {
      body.parentAsin = rowNode.data.parent_asin;
      body.category = rowNode.data.category;
      body.subcategory = rowNode.data.subcategory;
      body.categoryNodeId = rowNode.data.category_node_id;
    }

    const requestOptions = {
      method: 'POST',
      headers: headerWithAuth,
      body: JSON.stringify(body),
    };
    // Call via API (would like to update to " await ")
    try {
      this.show('secondary', 'Update Request', `ASIN ${rowNode.data.asin}, SKU: ${rowNode.data.sku}.`);
      const data = await doFetch(apiEndPoint, requestOptions);
      this.show('primary', 'Success', `ASIN ${data[0].asin || rowNode.data.asin}, SKU: ${data[0].sku || rowNode.data.sku} Updated.`);
    } catch (error) {
      this.show('danger', 'Error', `ASIN ${rowNode.data.asin}, SKU: ${rowNode.data.sku} was not saved.`);
    }
  }

  render() {
    const {
      columnDefs, defaultColDef, onCellEditingStopped, showDatePicker, showImportButton, showFooter, pdOptions, frameworkComponents, parentControlledLoad, getColDefs,
    } = this.state;

    const {
      loggedIn, user, sellerSelect, accountConfig,
    } = this.props;

    const apiEndPoint = `${apiUrl}/${accountConfig.endpoint}`;
    const paginationPageSize = 5000;
    const cacheBlockSize = paginationPageSize;

    const datasource = {
      getRows: async (params) => {
        const {
          request, success, fail,
        } = params;
        const { startRow, sortModel } = request;
        const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
        const requestOptions = {
          method: 'GET',
          headers: headerWithAuth,
        };

        let sort = '';
        if (sortModel.length > 0) {
          sort = `${sortModel[0].colId} ${sortModel[0].sort}`;
        }

        const offset = startRow || 0;

        const url = `${apiEndPoint}?sellerAccountId=${sellerSelect.value.toString()}&parentAccountId=${sellerSelect.parentAccountId}&currencyCode=${(sellerSelect.currencyCode || 'USD')}&limit=${paginationPageSize}&offset=${offset}&sort=${sort}&csaaFlag=true&pmFlag=true`;
        try {
          const data = await doFetch(url, requestOptions);
          success({
            rowData: data.results,
            rowCount: data.total,
          });
        } catch (error) {
          fail();
        }
      },
    };

    const accessControl = JSON.parse(user.access_control);
    const showImportOverride = (loggedIn && (user.access === 'admin' || user.access === 'agency' || accessControl.accountControlRole)) ? true : showImportButton;

    const exportPrefix = getExportPrefix('amz-catalog-mgr', sellerSelect);

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        onCellEditingStopped={onCellEditingStopped}
        showDatePicker={showDatePicker}
        showImportButton={showImportOverride}
        showFooter={showFooter}
        parentProcessChange={this.parentProcessChange}
        importMessage="Import a FULL file like the one exported.   This will replace all suplement data (master table), so please include a full file."
        uploadPath={accountConfig.uploadPath}
        productDetailOptions={pdOptions}
        frameworkComponents={frameworkComponents}
        rowHeight={75}
        autoSizeAll
        pagination
        rowModelType={'serverSide'}
        serverSideInfiniteScroll
        datasource={datasource}
        colDefUpdate={getColDefs(sellerSelect.type)}
        paginationPageSize={paginationPageSize}
        cacheBlockSize={cacheBlockSize}
        parentControlledLoad={parentControlledLoad}
        exportPrefix={exportPrefix}
        confirmLogic
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { loggedIn, user } = state.authentication;
  const { sellerSelect } = state;

  return {
    user,
    sellerSelect,
    loggedIn,
  };
};

export default connect(mapStateToProps)(AllCatalogTable);
