import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import {
  currencyFormatter, getExportPrefix, toDisplayDate,
} from '../../../../shared/components/table/functions';
import { imageFieldColumn, imageCellRendererComponent } from '../../../../shared/components/table/ImageCellRenderer';
import { doFetch } from '../../../../helpers';
import { SellerSelect, User } from '../../../../shared/prop-types/MainProps';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;
class SellerCatalogTable extends PureComponent {
  static propTypes = {
    user: User.isRequired,
    sellerSelect: SellerSelect.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        imageFieldColumn,
        { field: 'sku', headerName: 'SKU' },
        { field: 'asin', headerName: 'ASIN' },
        { field: 'product_title', headerName: 'Title' },
        {
          field: 'list_price',
          headerName: 'Price',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'open_date',
          headerName: 'Open Date',
          valueFormatter: toDisplayDate,
        },
        {
          field: 'unit_cog',
          headerName: 'Unit COG',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'brand',
          headerName: 'Brand',
        },
        {
          field: 'ad_status',
          headerName: 'Ad Status',
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: ['on', 'off'],
          },
        },
        {
          field: 'reporting_visibility',
          headerName: 'Reporting Visibility',
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: ['on', 'off'],
          },
        },
        {
          field: 'segment_one',
          headerName: 'Segment 1',
        },
        {
          field: 'segment_two',
          headerName: 'Segment 2',
        },
        {
          field: 'segment_three',
          headerName: 'Segment 3',
        },
        {
          field: 'segment_four',
          headerName: 'Segment 4',
        },
        {
          field: 'segment_five',
          headerName: 'Segment 5',
        },
        {
          field: 'size',
          headerName: 'Size',
        },
        {
          field: 'color',
          headerName: 'Color',
        },
        {
          field: 'budget_group',
          headerName: 'Budget Group',
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: false,
        resizable: true,
        cellStyle: (params) => {
          if (params && params.value < 0.00) {
            return { color: 'red' };
          }
          return null;
        },
      },
      frameworkComponents: {
        ...imageCellRendererComponent,
      },
      showDatePicker: false,
      showFooter: false,
      parentControlledLoad: true,
    };
  }

  render() {
    const {
      columnDefs, defaultColDef, showDatePicker, showFooter, pdOptions, frameworkComponents, parentControlledLoad,
    } = this.state;

    const { user, sellerSelect } = this.props;

    const apiEndPoint = `${apiUrl}/sellers/sku-catalog`;
    const paginationPageSize = 5000;
    const cacheBlockSize = paginationPageSize;
    const exportPrefix = getExportPrefix('amz-catalog', sellerSelect, true);

    const datasource = {
      getRows: async (params) => {
        const {
          request, success, fail,
        } = params;
        const { startRow, sortModel } = request;
        const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
        const requestOptions = {
          method: 'GET',
          headers: headerWithAuth,
        };

        let sort = '';
        if (sortModel.length > 0) {
          sort = `${sortModel[0].colId} ${sortModel[0].sort}`;
        }

        const offset = startRow || 0;

        const url = `${apiEndPoint}?sellerAccountId=${sellerSelect.value.toString()}&parentAccountId=${sellerSelect.parentAccountId}&currencyCode=${(sellerSelect.currencyCode || 'USD')}&accountType=${sellerSelect.type}&limit=${paginationPageSize}&offset=${offset}&sort=${sort}`;
        try {
          const data = await doFetch(url, requestOptions);
          success({
            rowData: data.results,
            rowCount: data.total,
          })
        } catch (error) {
          fail();
        }
      },
    };

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        showDatePicker={showDatePicker}
        showFooter={showFooter}
        productDetailOptions={pdOptions}
        frameworkComponents={frameworkComponents}
        rowHeight={75}
        autoSizeAll
        pagination="true"
        rowModelType="serverSide"
        datasource={datasource}
        paginationPageSize={paginationPageSize}
        cacheBlockSize={cacheBlockSize}
        serverSideInfiniteScroll
        parentControlledLoad={parentControlledLoad}
        exportPrefix={exportPrefix}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state.authentication;
  const { sellerSelect } = state;

  return {
    user,
    sellerSelect,
  };
};

export default connect(mapStateToProps)(SellerCatalogTable);
