import React, { useState } from 'react';
import {
  Route, Switch, Redirect,
} from 'react-router-dom';
import Layout from '../Layout/index';
import OnboardingLayout from '../Layout/indexOnboarding';
import MainWrapper from './MainWrapper';
import useAgency from '../../shared/components/hooks/useAgency';
import useAccountTypeName from '../../shared/components/hooks/useAccountTypeName';
import DashboardAlert from '../../shared/components/widgets/DashboardAlert';

import Profile from '../Account/Profile/index';
import ForgotPassword from '../Account/ForgotPassword/index';
import LogIn from '../Account/LogIn/index';
import Register from '../Account/Register/index';

// Customer Pages
import ProfitReport from '../Customer/Profits/index';
import Dashboard from '../Customer/Dashboard/index';
import LaunchResults from '../Customer/LaunchCentral/indexResults';
import LaunchSettings from '../Customer/LaunchCentral/indexSettings';
import CatalogIndexPage from '../Customer/Catalog/index';
import VendorSalesManufacturing from '../Customer/Vendor/indexSalesManufacturing';
import VendorSalesSourcing from '../Customer/Vendor/indexSalesSourcing';
import VendorSalesSummary from '../Customer/Vendor/indexSalesSummary';
import VendorSalesShare from '../Customer/Vendor/indexSalesShare';
import VendorSalesSegmentShare from '../Customer/Vendor/indexSalesSegmentShare';
import OneThreeBrandMarketShare from '../Customer/MarketShare/oneThreeIndex';
import TopTenBrandMarketShare from '../Customer/MarketShare/topTenBrandIndex';
import TopTenAsinMarketShare from '../Customer/MarketShare/topTenAsinIndex';
import SellerPpcProfitReport from '../Customer/PayPerClick/Seller/PpcProfitReport';
import SellerAsinBrandPerformance from '../Customer/SellerAsinBrandPerformance/index';
import VendorBestsellers from '../Customer/PayPerClick/Vendor/PpcBestsellers';
import SellerBestsellers from '../Customer/PayPerClick/Seller/PpcBestsellers';
import VendorLbbReport from '../Customer/Inventory/indexLbb';
import VendorOosReport from '../Customer/Inventory/indexOos';
import VendorPpcStats from '../Customer/PayPerClick/Vendor/PpcStats';
import SponsoredBrandStats from '../Customer/ProductAds/indexSbStats';
import VendorPpcSubcatRank from '../Customer/PayPerClick/Vendor/PpcSubcatRank';
import VendorInventoryHealth from '../Customer/Inventory/indexHealth';
import PpcDateRangeOverDateRange from '../Customer/PayPerClick/PpcDateRangeOverDateRange';
import VendorAccountSummary from '../Customer/Dashboard/indexAccountSummary';
import Budget from '../Customer/Budget/index';
import BudgetPacing from '../Customer/Budget/indexBudgetPacing';
import RankAnalytics from '../Customer/SalesRank/indexRankAnalytics';
import RankDistribution from '../Customer/SalesRank/indexRankDistribution';
import CustomerCalendar from '../Customer/Calendar/indexCalendar';
import CustomerCalendarManager from '../Customer/Calendar/indexManager';
import PpcOverTimePortfolios from '../Customer/PayPerClick/PpcOverTimePortfolios';
import PpcOverTimeAccount from '../Customer/PayPerClick/PpcOverTimeAccount';
import PpcOverTimeAsins from '../Customer/PayPerClick/PpcOverTimeAsins';
import PpcOverTimeBrandsPlus from '../Customer/PayPerClick/PpcOverTimeBrandsPlus';
import PpcOverTimeCategoriesPlus from '../Customer/PayPerClick/PpcOverTimeCategoriesPlus';
import BccAccountPerformance from '../Customer/BrandCategoryCompetitor/BccAccountPerformance';
import BccTopCompetitors from '../Customer/BrandCategoryCompetitor/BccTopCompetitors';
import BccSearchTerms from '../Customer/BrandCategoryCompetitor/BccSearchTerms';
import ProductEligibility from '../Customer/ProductEligibility/index';
import PpcDataReport from '../Customer/PayPerClick/PpcDataReport';
import PpcAndOrganicPerformance from '../Customer/PayPerClick/PpcAndOrganicPerformance';
import CustomerPpcReport from '../Customer/PayPerClick/PpcReport';
import WalmartSearchTrendsReport from '../Customer/SearchTrends/indexWalmart';
import AmazonSearchTrendsReport from '../Customer/SearchTrends/indexAmazon';

// Insights Pages
import insightDashboard from '../Customer/Insights/index';

// Dashboard pages
import DynamicExecDashboard from '../Customer/AccountDashboard/index';
import AmazonExecDashboard from '../Customer/AccountDashboard/indexAmazon';
import WalmartExecDashboard from '../Customer/AccountDashboard/indexWalmart';

// Admin Pages
import Customers from '../Admin/Customers/index';
import EventActivity from '../Admin/Activity/index';
import EventCalendar from '../Admin/Calendar/index';
import ProductAds from '../Customer/ProductAds/index';
import Accounts from '../Admin/Accounts/index';
import Users from '../Admin/Users/index';
import AccountSetting from '../Admin/Accounts/AccountSettings';
import AccountAmcInstance from '../Admin/Accounts/AccountAmcInstance';
import DataCheck from '../Admin/DataCheck/index';
import DataCheckAmzProductsOffImp from '../Admin/DataCheck/indexAmzProductsOffImp';
import FileLoader from '../Admin/FileLoader/index';
import AccountSettingsManagement from '../Admin/Accounts/AccountSettingsMgmt';
import AccountStatusAll from '../Admin/Accounts/AccountStatusAll';
import AccountStatus from '../Admin/Accounts/AccountStatus';
import AccountStreams from '../Admin/Streams/index';

// Account Mgmt Pages
import AccountManagmentTodo from '../AccountManagment/Todo/index';
import AccountManagmentBudget from '../AccountManagment/Budget/index';
import AccountManagmentBudgetForecast from '../AccountManagment/BudgetForecast/index'
import AccountManagmentBudgetStats from '../AccountManagment/BudgetStats/index';
import AccountManagmentCatalogAmazon from '../AccountManagment/Catalog/Amazon/index';
import AccountManagmentCatalogWalmart from '../AccountManagment/Catalog/Walmart/index';
import AccountManagmentAccountView from '../AccountManagment/AccountView/index';
import AccountManagmentContactCenterView from '../AccountManagment/ContactCenter/index';
import AccountManagmentProductMetrics from '../AccountManagment/ProductMetrics/index';

// Agency Pages
import AgencyBudget from '../Agency/Budget/index';

// Status Pages
import StatusHome from '../Status/index';

// Estimate Pages
import SubscriptionEstimate from '../Customer/Subscription/SubscriptionEstimate';
import ToolCalculatorPage from '../Customer/Subscription/ToolCalculatorPage';

// Freemium Home
import FreemiumHome from '../Customer/Freemium/index'; // Home page for freemium model

// OAuth Capture
import CaptureOauth from '../Onboarding/oauth/index';

// Amazon Onboarding Pages
import OnboardingHome from '../Onboarding/amazon/index';
import AuthorizeAdApi from '../Onboarding/amazon/authAdApiIndex';
import AuthorizeAdApiStatus from '../Onboarding/amazon/authAdApiStatusIndex';
import AuthorizeSpApi from '../Onboarding/amazon/authSpApiIndex';
import AuthorizeSpApiStatus from '../Onboarding/amazon/authSpApiStatusIndex';

// Walmart Onboarding Pages
import WalmartAdApiOnboardStepOne from '../Onboarding/walmart/walmartOnboardStepOne';
import WalmartAdApiOnboardStepTwo from '../Onboarding/walmart/walmartOnboardStepTwo';
import AuthorizeWalmartAdApi from '../Onboarding/walmart/authAdApiIndex';
import AuthorizeWalmartMpApi from '../Onboarding/walmart/authMpApiIndex';

// JTSR - Justin Temporary Scraper-Ripper
import JtsrAsin from '../Customer/Jtsr/index';
import JtsrAsinByPage from '../Customer/Jtsr/byPage';

import Home from '../Home/index';
import GetStarted from '../Home/indexStart';

// Secure Routing
import PrivateRoute from './PrivateRoute';
import AdminRoute from './AdminRoute';
import AgencyRoute from './AgencyRoute';
import AccountControlRoute from './AccountControlRoute';
import CustomerRoute from './CustomerRoute';
import SubscriptionRoute from './SubscriptionRoute';
import useLogoOnboarding from '../../shared/components/hooks/useLogoOnboarding';

// AdRev Pages
import SubscriptionPlan from '../Customer/Subscription/PlanPage';
import SubscriptionPlanStatus from '../Customer/Subscription/PlanStatus';
import SubscriptionFaq from '../Customer/Subscription/FaqPage'
import WalmartOnePage from '../Customer/Freemium/walmartOnePage';

// DSP Pages
import DspCampaignReport from '../Customer/Dsp/CampaignReport';
import DspInventoryReport from '../Customer/Dsp/InventoryReport';
import DspOrderPerformance from '../Customer/Dsp/OrderPerformance';

// Walmart Pages
import WalmartItemCatalog from '../Customer/Walmart/ItemCatalog';
import WalmartPpcReport from '../Customer/Walmart/PpcReport';
import WalmartQueryReports from '../Customer/Walmart/QueryReports';
import WalmartMpOrders from '../Customer/Walmart/MpOrders';

const AccountRoutes = () => (
  <Switch>
    <Route path="/account/profile" component={Profile} />
  </Switch>
);

const AdminRoutes = () => (
  <Switch>
    <Route path="/admin/customers" component={Customers} />
    <Route path="/admin/event-activity" component={EventActivity} />
    <Route path="/admin/event-calendar" component={EventCalendar} />
    <Route path="/admin/account/streams" component={AccountStreams} />
    <Route path="/admin/account/status-all" component={AccountStatusAll} />
    <Route path="/admin/account/status" component={AccountStatus} />
    <Route path="/admin/accounts" component={Accounts} />
    <Route path="/admin/users" component={Users} />
    <Route path="/admin/account/setting" component={AccountSetting} />
    <Route path="/admin/account/amc-instance" component={AccountAmcInstance} />
    <Route path="/admin/data-check-apoi" component={DataCheckAmzProductsOffImp} />
    <Route path="/admin/data-check" component={DataCheck} />
    <Route path="/admin/file-loader" component={FileLoader} />
    <Route path="/admin/account-view" component={AccountManagmentAccountView} />
    <Route path="/admin/account/settings" component={AccountSettingsManagement} />
  </Switch>
);

const AgencyRoutes = () => (
  <Switch>
    <Route path="/agency/account/status" component={AccountStatus} />
    <Route path="/agency/budget" component={AccountManagmentBudget} />
    <Route path="/agency/budget-awl" component={AgencyBudget} />
    <Route path="/agency/amz-budget-forecast">
      <AccountManagmentBudgetForecast retailer="amazon" />
    </Route>
    <Route path="/agency/wm-budget-forecast">
      <AccountManagmentBudgetForecast retailer="walmart" />
    </Route>
    <Route path="/agency/catalog-amazon" component={AccountManagmentCatalogAmazon} />
    <Route path="/agency/catalog-walmart" component={AccountManagmentCatalogWalmart} />
  </Switch>
);

const JtsrRoutes = () => (
  <Switch>
    <Route path="/jtsr/asin" component={JtsrAsin} />
    <Route path="/jtsr/asin-by-page" component={JtsrAsinByPage} />
  </Switch>
);

// Start moving these out of Customer and into the approprate cust type (vendor/seller)
const CustomerRoutes = () => (
  <Switch>
    <Route exact path="/customer/walmart-free-ads" component={WalmartOnePage} />
    <Route path="/customer/product-ads" component={ProductAds} />
    <Route path="/customer/sponsored-brand-stats" component={SponsoredBrandStats} />
    <Route path="/customer/one-three-market-share" component={OneThreeBrandMarketShare} />
    <Route path="/customer/top-ten-brand-market-share" component={TopTenBrandMarketShare} />
    <Route path="/customer/top-ten-asin-market-share" component={TopTenAsinMarketShare} />
    <Route path="/customer/onboarding/ad-api/start" component={AuthorizeAdApi} />
    <Route path="/customer/onboarding/sp-api/start" component={AuthorizeSpApi} />
    <Route path="/customer/onboarding/walmart/ad-api" component={AuthorizeWalmartAdApi} />
    <Route path="/customer/onboarding/walmart/mp-api" component={AuthorizeWalmartMpApi} />
    <Route path="/customer/launch-results" component={LaunchResults} />
    <Route path="/customer/launch-settings" component={LaunchSettings} />
    <Route path="/customer/seller-asin-brand-performance" component={SellerAsinBrandPerformance} />
    <Route path="/customer/budget" component={Budget} />
    <Route path="/customer/budget-pacing" component={BudgetPacing} />
    <Route path="/customer/sales-rank-analytics" component={RankAnalytics} />
    <Route path="/customer/sales-rank-distribution" component={RankDistribution} />
    <Route path="/customer/calendar" component={CustomerCalendar} />
    <Route path="/customer/calendar-manager" component={CustomerCalendarManager} />
    <Route path="/customer/get-started" component={GetStarted} />
    <Route path="/customer/ppc-ot-account" component={PpcOverTimeAccount} />
    <Route path="/customer/ppc-ot-portfolios" component={PpcOverTimePortfolios} />
    <Route path="/customer/ppc-ot-asins" component={PpcOverTimeAsins} />
    <Route path="/customer/ppc-ot-brands-plus" component={PpcOverTimeBrandsPlus} />
    <Route path="/customer/ppc-ot-categories-plus" component={PpcOverTimeCategoriesPlus} />
    <Route path="/customer/bcc/account-performance" component={BccAccountPerformance} />
    <Route path="/customer/bcc/top-competitors" component={BccTopCompetitors} />
    <Route path="/customer/bcc/search-terms" component={BccSearchTerms} />
    <Route path="/customer/product-eligibility" component={ProductEligibility} />
    <Route path="/customer/ppc-data-report" component={PpcDataReport} />
    <Route path="/customer/account-dashboard" component={DynamicExecDashboard} />
    <Route path="/customer/ppc-report" component={CustomerPpcReport} />
    <Route path="/customer/catalog" component={CatalogIndexPage} />
    <Route path="/customer/profit-report" component={ProfitReport} />
    <Route path="/customer/walmart-search-trends" component={WalmartSearchTrendsReport} />
    <Route path="/customer/amazon-search-trends" component={AmazonSearchTrendsReport} />
  </Switch>
);

const SubscriptionRoutes = ({ ...props }) => (
  <Switch>
    <Route exact path="/subscription/faq" component={SubscriptionFaq} />
    <Route exact path="/subscription/plan">
      <SubscriptionPlan {...props} />
    </Route>
    <Route exact path="/subscription/:ecidsubid/complete" component={SubscriptionPlanStatus} />
    <Route exact path="/subscription/:ecidsubidpmid/update">
      <SubscriptionPlanStatus action="paymentMethodUpdate" />
    </Route>
  </Switch>
);

const AccountManagementRoutes = () => (
  <Switch>
    <Route path="/account-management/budget" component={AccountManagmentBudget} />
    <Route path="/account-management/amz-budget-forecast">
      <AccountManagmentBudgetForecast retailer="amazon" />
    </Route>
    <Route path="/account-management/wm-budget-forecast">
      <AccountManagmentBudgetForecast retailer="walmart" />
    </Route>
    <Route path="/account-management/budget-stats" component={AccountManagmentBudgetStats} />
    <Route path="/account-management/catalog-amazon" component={AccountManagmentCatalogAmazon} />
    <Route path="/account-management/catalog-walmart" component={AccountManagmentCatalogWalmart} />
    <Route path="/account-management/contact-center" component={AccountManagmentContactCenterView} />
    <Route path="/account-management/product-metrics" component={AccountManagmentProductMetrics} />
    <Route path="/account-management/todo" component={AccountManagmentTodo} />
  </Switch>
);

const insightRoutes = () => (
  <div>
    <Route path="/insight" component={insightDashboard} />
  </div>
);

const SellerRoutes = () => (
  <Switch>
    <Route path="/seller/sku-catalog" component={CatalogIndexPage}>
      <Redirect
        to={{
          pathname: '/customer/catalog',
        }}
      />
    </Route>
    <Route path="/seller/dashboard" component={Dashboard} />
    <Route path="/seller/profit-report" component={ProfitReport} />
    <Route path="/seller/ppc-profit-report" component={SellerPpcProfitReport} />
    <Route path="/seller/ppc-bestsellers" component={SellerBestsellers} />
  </Switch>
);

const VendorRoutes = () => (
  <Switch>
    <Route path="/vendor/asin-catalog" component={CatalogIndexPage}>
      <Redirect
        to={{
          pathname: '/customer/catalog',
        }}
      />
    </Route>
    <Route path="/vendor/ppc-bestsellers" component={VendorBestsellers} />
    <Route path="/vendor/sales-manufacturing" component={VendorSalesManufacturing} />
    <Route path="/vendor/sales-sourcing" component={VendorSalesSourcing} />
    <Route path="/vendor/sales-summary" component={VendorSalesSummary} />
    <Route path="/vendor/sales-share" component={VendorSalesShare} />
    <Route path="/vendor/sales-segment-share" component={VendorSalesSegmentShare} />
    <Route path="/vendor/ppc-stats" component={VendorPpcStats} />
    <Route path="/vendor/ppc-subcat-rank" component={VendorPpcSubcatRank} />
    <Route path="/vendor/inventory-health" component={VendorInventoryHealth} />
    <Route path="/vendor/ppc-daterange-over-daterange" component={PpcDateRangeOverDateRange} />
    <Route path="/vendor/account-summary" component={VendorAccountSummary} />
    <Route path="/vendor/lbb-report" component={VendorLbbReport} />
    <Route path="/vendor/oos-report" component={VendorOosReport} />
    <Route path="/vendor/ppc-organic-performance" component={PpcAndOrganicPerformance} />
  </Switch>
);

const dspRoutes = () => (
  <div>
    <Route path="/dsp/campaign-report" component={DspCampaignReport} />
    <Route path="/dsp/inventory-report" component={DspInventoryReport} />
    <Route path="/dsp/order-performance" component={DspOrderPerformance} />
  </div>
);

const wmRoutes = () => (
  <div>
    <Route path="/walmart/exec-view" component={WalmartExecDashboard} />
    <Route path="/walmart/item-catalog" component={WalmartItemCatalog} />
    <Route path="/walmart/ppc-report" component={WalmartPpcReport} />
    <Route path="/walmart/query-reports" component={WalmartQueryReports} />
    <Route path="/walmart/mp-orders" component={WalmartMpOrders} />
  </div>
);

const wrappedRoutes = () => {
  const [selectedPlan, setSelectedPlan] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState('');
  return (
    <div>
      <Layout />
      <div className="container__wrap">
        <DashboardAlert />
        <Route exact path="/" component={DynamicExecDashboard} />
        <Route path="/account" component={AccountRoutes} />
        <AdminRoute path="/admin" component={AdminRoutes} />
        <AgencyRoute path="/agency" component={AgencyRoutes} />
        <CustomerRoute path="/customer" component={CustomerRoutes} />
        <Route path="/jtsr" component={JtsrRoutes} />
        <AccountControlRoute path="/account-management" component={AccountManagementRoutes} />
        <Route path="/insight" component={insightRoutes} />
        <Route path="/seller" component={SellerRoutes} />
        <Route path="/vendor" component={VendorRoutes} />
        <Route path="/about" component={Home} />
        <Route path="/home" component={DynamicExecDashboard} />
        <Route path="/dsp" component={dspRoutes} />
        <Route path="/amazon/exec-view" component={AmazonExecDashboard} />
        <Route path="/walmart" component={wmRoutes} />
        <SubscriptionRoute
          path="/subscription"
          component={SubscriptionRoutes}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          clientSecret={clientSecret}
          setClientSecret={setClientSecret}
          selectedSubscriptionId={selectedSubscriptionId}
          setSelectedSubscriptionId={setSelectedSubscriptionId}
        />
      </div>
    </div>
  );
};

const wrappedOnboarding = () => {
  const {
    agencyCode, logoImage, accountTypeName,
  } = (function iife() {
    const agency = useAgency();
    const logo = useLogoOnboarding(agency);
    const accountTypeNameFound = useAccountTypeName();
    return {
      agencyCode: agency,
      logoImage: logo,
      accountTypeName: accountTypeNameFound,
    };
  }());
  return (
    <div>
      <OnboardingLayout logoImage={logoImage} />
      <div className="container__wrap">
        <DashboardAlert />
        <Switch>
          <Route exact path="/onboarding">
            <OnboardingHome agencyCode={agencyCode} key="onboarding" />
          </Route>
          <Route exact path="/onboarding/oauth/amazon">
            <CaptureOauth key="oauth-amazon" adPlatform="amazon" />
          </Route>
          <Route exact path="/onboarding/oauth/google">
            <CaptureOauth key="oauth-google" adPlatform="google" />
          </Route>
          <Route exact path="/onboarding/oauth/walmart">
            <CaptureOauth key="oauth-walmart" adPlatform="walmart" />
          </Route>
          <Route exact path="/onboarding/sp-api/start">
            <AuthorizeSpApi key="start-sp-api-onboarding" displayLink="no" apiType="sp-api" />
          </Route>
          <Route exact path="/onboarding/sp-api/status">
            <AuthorizeSpApiStatus key="status-sp-api-onboarding" />
          </Route>
          <Route exact path="/onboarding/ad-api/start">
            <AuthorizeAdApi key="start-ad-api-onboarding" apiType="ad-api" />
          </Route>
          <Route exact path="/onboarding/ad-api/status/:accountTypeName">
            <AuthorizeAdApiStatus accountTypeName={accountTypeName} key="status-ad-api-onboarding" />
          </Route>
          <Route exact path="/onboarding/walmart">
            <WalmartAdApiOnboardStepOne key="walmart-onboard" adProgram="PAID" />
          </Route>
          <Route exact path="/onboarding/walmart/step-one">
            <WalmartAdApiOnboardStepOne key="walmart-step-one" adProgram="PAID" />
          </Route>
          <Route exact path="/onboarding/walmart/step-two">
            <WalmartAdApiOnboardStepTwo key="walmart-step-two" adProgram="PAID" />
          </Route>
          <Route exact path="/onboarding/walmart/ad-api">
            <AuthorizeWalmartAdApi key="walmart-ad-onboard" displayLink="no" adProgram="PAID" />
          </Route>
          <Route exact path="/onboarding/walmart/mp-api">
            <AuthorizeWalmartMpApi key="walmart-mp-auth" displayLink="no" adProgram="PAID" />
          </Route>
          <Route exact path="/onboarding/walmart/:agencyCode">
            <WalmartAdApiOnboardStepOne agencyCode={agencyCode} key="walmart-onboard-ad-agency" adProgram="PAID" />
          </Route>
          <Route exact path="/onboarding/walmart/:agencyCode/ad-api">
            <AuthorizeWalmartAdApi agencyCode={agencyCode} displayLink="no" key="walmart-onboard-ad-agency" adProgram="PAID" />
          </Route>
          <Route exact path="/onboarding/walmart/:agencyCode/mp-api">
            <AuthorizeWalmartMpApi agencyCode={agencyCode} displayLink="no" key="walmart-onboard-mp-agency" adProgram="PAID" />
          </Route>
          <Route exact path="/onboarding/:agencyCode">
            <OnboardingHome agencyCode={agencyCode} key="onboarding-agency" />
          </Route>
          <Route exact path="/onboarding/:agencyCode/sp-api/start">
            <AuthorizeSpApi agencyCode={agencyCode} key="start-sp-api-onboarding" displayLink="no" apiType="sp-api" />
          </Route>
          <Route exact path="/onboarding/:agencyCode/ad-api/start">
            <AuthorizeAdApi agencyCode={agencyCode} key="start-ad-api-onboarding" apiType="ad-api" />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

const wrappedFreemium = () => (
  <div>
    <OnboardingLayout />
    <div className="container__wrap">
      <DashboardAlert />
      <Switch>
        <Route exact path="/free">
          <FreemiumHome key="freemium" />
        </Route>
        <Route exact path="/free/walmart">
          <WalmartAdApiOnboardStepOne key="freemium-walmart-step-one-shortcut" adProgram="FREE" />
        </Route>
        <Route exact path="/free/walmart/step-one">
          <WalmartAdApiOnboardStepOne key="freemium-walmart-step-one" adProgram="FREE" />
        </Route>
        <Route exact path="/free/walmart/step-two">
          <WalmartAdApiOnboardStepTwo key="freemium-walmart-step-two" adProgram="FREE" />
        </Route>
      </Switch>
    </div>
  </div>
);

const wrappedStatus = () => {
  const {
    logoImage,
  } = (function iife() {
    const agency = useAgency();
    const logo = useLogoOnboarding(agency);
    return {
      agencyCode: agency,
      logoImage: logo,
    };
  }());
  return (
    <div>
      <OnboardingLayout logoImage={logoImage} />
      <br /><br />
      <div className="container__wrap">
        <DashboardAlert />
        <Switch>
          <Route exact path="/status" component={StatusHome} />
        </Switch>
      </div>
    </div>
  );
};

const wrappedCalculator = () => (
  <Switch>
    <Route exact path="/calculator/subscription" component={SubscriptionEstimate} />
    <Route exact path="/calculator/tool/:toolKey" component={ToolCalculatorPage} />
  </Switch>
);


// eslint-disable-next-line
const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/login" component={LogIn} />
        <Route exact path="/logout" component={LogIn} />
        <Route path="/register" component={Register} />
        <Route path="/forgot" component={ForgotPassword} />
        <Route path="/onboarding" component={wrappedOnboarding} />
        <Route path="/free" component={wrappedFreemium} />
        <Route path="/status" component={wrappedStatus} />
        <Route path="/calculator" component={wrappedCalculator} />
        <PrivateRoute path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
