import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getLocalStorage, setLocalStorage } from '../../../shared/storage';

export default class SidebarCategory extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    scope: PropTypes.string,
    icon: PropTypes.string,
    isAdmin: PropTypes.bool,
    isAgency: PropTypes.bool,
    isLegacy: PropTypes.bool,
    isBeta: PropTypes.bool,
    isDev: PropTypes.bool,
    isNew: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element,
    ]).isRequired,
  };

  static defaultProps = {
    scope: '',
    icon: '',
    isAdmin: false,
    isAgency: false,
    isLegacy: false,
    isBeta: false,
    isDev: false,
    isNew: false,
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    const { collapse } = this.state;
    const { scope, title } = this.props;
    const openSet = getLocalStorage('openGutter') && getLocalStorage('openGutter') !== '{}' ? new Set(JSON.parse(getLocalStorage('openGutter'))) : new Set();
    if (!collapse) {
      openSet.add(`${scope}/${title}`);
      setLocalStorage('openGutter', JSON.stringify(Array.from(openSet)));
    } else {
      openSet.delete(`${scope}/${title}`);
      setLocalStorage('openGutter', JSON.stringify(Array.from(openSet)));
    }
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  componentDidMount() {
    const { scope, title } = this.props;
    const { collapse } = this.state;
    const openSet = getLocalStorage('openGutter') && getLocalStorage('openGutter') !== '{}' ? new Set(JSON.parse(getLocalStorage('openGutter'))) : new Set();
    if (openSet.has(`${scope}/${title}`)) {
      if (!collapse) {
        this.toggle();
      }
    }
  }

  render() {
    const {
      title, icon, isAdmin, isAgency, isLegacy, isBeta, isDev, isNew, children,
    } = this.props;
    const { collapse } = this.state;
    const categoryClass = classNames({
      'sidebar__category-wrap': true,
      'sidebar__category-wrap--open': collapse,
    });

    return (
      <div className={categoryClass}>
        <button type="button" className="sidebar__link sidebar__category" onClick={this.toggle}>
          {icon ? <span className={`sidebar__link-icon lnr lnr-${icon}`} /> : ''}
          <p className="sidebar__link-title">{title}
            {isAdmin && <span className="sidebar__category-admin" />}
            {isAgency && <span className="sidebar__category-agency" />}
            {isLegacy && <span className="sidebar__category-legacy" />}
            {isBeta && <span className="sidebar__category-beta" />}
            {isDev && <span className="sidebar__category-dev" />}
            {isNew && <span className="sidebar__category-new" />}
          </p>
          <span className="sidebar__category-icon lnr lnr-chevron-right" />
        </button>
        <Collapse isOpen={collapse} className="sidebar__submenu-wrap">
          <ul className="sidebar__submenu">
            <div>
              {children}
            </div>
          </ul>
        </Collapse>
      </div>
    );
  }
}
