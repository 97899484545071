import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import classNames from 'classnames';
import { Button, ButtonToolbar } from 'reactstrap';
import moment from 'moment';
import Picker from 'rsuite/DatePicker'
import lodash from 'lodash'
import Select from 'react-select';
import validate from './validate';

const renderFieldTodo = ({
  input, placeholder, type, isDisabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} disabled={isDisabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderFieldTodo.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  isDisabled: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderFieldTodo.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  isDisabled: '',
};

const renderDatePicker = ({ dueDate, handleDateChange }) => {
  const [pickerId] = useState(lodash.uniqueId('date-picker-'));
  const theme = useSelector(store => store.theme);
  const pickerClasses = classNames(theme.className, theme.className.endsWith('light') ? 'rs-theme-light' : 'rs-theme-dark');
  return (

    <div id={pickerId} style={{ position: 'absolute' }} className={pickerClasses}>
      <Picker
        value={dueDate ? moment(dueDate).toDate() : ''}
        onChange={handleDateChange}
        container={document.getElementById(pickerId)}
        format="yyyy-MM-dd"
        oneTap
      />
    </div>
  )
};

renderDatePicker.propTypes = {
  handleDateChange: PropTypes.func.isRequired,
  dueDate: PropTypes.shape().isRequired,
};

const ItemEditModalForm = ({
  handleSubmit, dueDate, handlePriorityChange, priority,
  handleDateChange, currentEditItem, changeShowEditModal, priorityOptions,
}) => (
  <form className="form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <span className="form__form-group-label typography-message">Title</span>
      <div className="form__form-group-field">
        <Field
          name="title"
          type="text"
          component={renderFieldTodo}
          placeholder="Title.."
          required
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Description</span>
      <div className="form__form-group-field">
        <Field
          component="textarea"
          name="description"
          placeholder="What to do.."
        />
      </div>
    </div>
    <div className="form__form-group">
      <div style={{ width: '50%', height: '50px', float: 'left' }}>
        <span className="form__form-group-label">Due Date</span>
        <div className="form__form-group-field priority">
          <Field
            name="dueDate"
            type="text"
            dueDate={dueDate}
            handleDateChange={handleDateChange}
            component={renderDatePicker}
            required
          />
        </div>
      </div>
      <div style={{ marginLeft: '50%', height: '50px' }}>
        <span className="form__form-group-label">Submit Date</span>
        <div className="form__form-group-field">
          <Field
            name="submittedDate"
            type="text"
            component={renderFieldTodo}
            isDisabled="disabled"
          />
        </div>
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Priority</span>
      <div className="form__form-group-field">
        <Select
          className="react-select"
          classNamePrefix="react-select"
          onChange={handlePriorityChange}
          options={priorityOptions}
          defaultValue={priority}
          clearable={false}
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Assigned To</span>
      <div className="form__form-group-field">
        <Field
          name="assignedToEmail"
          type="text"
          component={renderFieldTodo}
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Account ID</span>
      <div className="form__form-group-field">
        <Field
          name="accountId"
          type="text"
          component={renderFieldTodo}
          isDisabled="disabled"
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Submitted By</span>
      <div className="form__form-group-field">
        <Field
          name="submittedByEmail"
          type="text"
          component={renderFieldTodo}
          isDisabled="disabled"
        />
      </div>
    </div>
    <ButtonToolbar className="form__button-toolbar">
      <Button color="primary" type="submit">
        {!(currentEditItem && currentEditItem.id) ? 'Add' : 'Edit'}
      </Button>
      <Button type="button" onClick={changeShowEditModal}>Cancel</Button>
    </ButtonToolbar>
  </form>
);

ItemEditModalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  changeShowEditModal: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handlePriorityChange: PropTypes.func.isRequired,
  currentEditItem: PropTypes.shape(),
  dueDate: PropTypes.shape().isRequired,
  priority: PropTypes.shape().isRequired,
  priorityOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

ItemEditModalForm.defaultProps = {
  currentEditItem: null,
};

export default reduxForm({
  form: 'todoItemEditModal',
  validate,
})(ItemEditModalForm);
