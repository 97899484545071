import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, reduxForm, change } from 'redux-form';
import { Button, ButtonToolbar } from 'reactstrap';
import CurrencyUsdIcon from 'mdi-react/CurrencyUsdIcon';
import Picker from 'rsuite/DatePicker';
import lodash from 'lodash';
import classNames from 'classnames';
import moment from 'moment';
import renderSelectField from '../../../../../shared/components/form/Select';
import renderCheckBoxField from '../../../../../shared/components/form/CheckBox';
import Notify from '../../../../../shared/components/widgets/Notify';
import config from '../../../../../config';

const renderFieldForm = ({
  input, placeholder, type, isDisabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} disabled={isDisabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

const renderDatePicker = ({
  input, handleDateChange, meta: { touched, error },
}) => {
  const [pickerId] = useState(lodash.uniqueId('date-picker-'));
  const theme = useSelector(store => store.theme);
  const pickerClasses = classNames(theme.className, theme.className.endsWith('light') ? 'rs-theme-light' : 'rs-theme-dark');
  return (
    <div id={pickerId} style={{ position: 'absolute' }} className={pickerClasses}>
      <Picker
        value={input.value ? new Date(input.value) : null}
        onChange={handleDateChange}
        container={document.getElementById(pickerId)}
        format="yyyy-MM-dd"
        oneTap
      />
      {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
  )
};

const EventEditModalForm = ({
  currentEditRow, changeShowEditModal, activeDayOptions, handleSubmit, setForceRefresh, budgetGroupOptions, setShowEditModal,
}) => {
  const selectedSeller = useSelector(store => store.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  // const formValues = useSelector(state => getFormValues('BulkManageCatalog')(state));
  const [showError, setShowError] = useState('');
  const [disableButton, setDisableButton] = useState(false);

  const stateOptions = [
    { label: 'Enabled', value: 'enabled' },
    { label: 'Disabled', value: 'disabled' },
    { label: 'Draft', value: 'draft' },
    { label: 'Completed', value: 'completed' },
  ];

  const submitForm = (formData) => {
    setDisableButton(true);
    const activeDaysArray = [];
    activeDayOptions.forEach(day => {
      activeDaysArray.push(formData[day.name] ? '1' : '0');
    });

    const submitEventData = {
      accountId: selectedSeller.value,
      budgetGroupEventDailyId: formData.budgetGroupEventDailyId || 0,
      budgetGroupDailyId: formData.budgetGroupDaily.value,
      eventName: formData.eventName,
      startDate: formData.startDate,
      endDate: formData.endDate,
      eventDailyBudgetAmount: formData.eventDailyBudgetAmount,
      activeDaysCsv: activeDaysArray.join(','),
      eventRampupApproval: formData.eventRampupApproval,
      state: formData.state.value,
    };

    const postUrl = `${config.serverUrl}/accounts/budget-group-event-daily`;
    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    const requestOptions = {
      method: 'POST',
      headers: headerWithAuth,
      body: JSON.stringify(submitEventData),
    };

    fetch(postUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then(() => {
        Notify.show('primary', 'Success', 'Your Budget Group Event has been updated.');
        setForceRefresh(moment().utc());
        changeShowEditModal();
        setDisableButton(false);
      }).catch((error) => {
        setShowError('We were unable to update your Event.');
        Notify.show('danger', 'Error', `We were unable to update your Event. Error: ${error}`);
        setDisableButton(false);
      });
  };

  function cleanName(value) {
    if (value) {
      return value.toString().replace(/[^\-0-9A-Za-z ]/, '').substring(0, 255);
    }
    return value;
  }

  function cleanNumber(value) {
    if (value) {
      return value.toString().match(/^[0-9]*(?:\.\d{0,2})?/)[0];
    }
    return value;
  }

  function initFormatTwoPlaces(value) {
    if (value) {
      return (((value || 0) * 100) / 100).toFixed(2);
    }
    return value;
  }

  const dispatch = useDispatch();
  const initDropdown = (name, value, options, position = 0) => {
    if (options) {
      dispatch(change('eventEditModalForm', name, value ? options.find(option => option.value === value) : options[position]));
    }
  }
  useEffect(() => {
    dispatch(change('eventEditModalForm', 'budgetGroupEventDailyId', currentEditRow?.budget_group_event_daily_id || 0));
    dispatch(change('eventEditModalForm', 'eventName', currentEditRow?.event_name || ''));
    initDropdown('budgetGroupDaily', currentEditRow?.budget_group_daily_id, budgetGroupOptions, 0);
    dispatch(change('eventEditModalForm', 'startDate', currentEditRow?.start_date ? moment(currentEditRow.start_date) : moment().add(1, 'day')));
    dispatch(change('eventEditModalForm', 'endDate', currentEditRow?.end_date ? moment(currentEditRow.end_date) : null));
    dispatch(change('eventEditModalForm', 'eventDailyBudgetAmount', initFormatTwoPlaces((currentEditRow?.event_daily_budget_amount || 0))));
    if (currentEditRow?.active_days_csv) {
      const activeDays = currentEditRow.active_days_csv.split(',');
      activeDays.forEach((day, pos) => {
        const activeDayObj = activeDayOptions.find(dayObj => dayObj.value === pos.toString());
        dispatch(change('eventEditModalForm', activeDayObj.name, day === '1'));
      });
    } else {
      activeDayOptions.forEach((day) => {
        dispatch(change('eventEditModalForm', day.name, true));
      });
    }
    dispatch(change('eventEditModalForm', 'eventRampupApproval', currentEditRow?.event_rampup_approval || 0));
    initDropdown('state', currentEditRow?.state, stateOptions, 0);
  }, []);


  useEffect(() => {
    initDropdown('budgetGroupDaily', currentEditRow?.budget_group_daily_id, budgetGroupOptions, 0);
  }, [budgetGroupOptions]);

  return (
    <>
      {budgetGroupOptions && budgetGroupOptions.length > 0 && (
        <form className="form" onSubmit={handleSubmit(submitForm)}>
          <h2>Manage Event</h2>
          <div className="form__form-group">
            <br />
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Event Name:</span>
            <div className="form__form-group-field">
              <Field
                name="eventName"
                component={renderFieldForm}
                type="text"
                format={cleanName}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Budget Group:</span>
            <div className="form__form-group-field">
              <Field
                name="budgetGroupDaily"
                type="text"
                component={renderSelectField}
                options={budgetGroupOptions}
              />
            </div>
          </div>
          <div className="form__form-group">
            <div style={{ width: '50%', height: '60px', float: 'left' }}>
              <span className="form__form-group-label">Start Date</span>
              <div>
                <Field
                  name="startDate"
                  type="text"
                  // setDate={startDate}
                  handleDateChange={(newDate) => dispatch(change('eventEditModalForm', 'startDate', newDate ? moment(newDate) : null))}
                  component={renderDatePicker}
                  required
                />
              </div>
            </div>
            <div style={{ marginLeft: '50%', height: '60px' }}>
              <span className="form__form-group-label">End Date</span>
              <div className="form__form-group-field">
                <Field
                  name="endDate"
                  type="text"
                  // setDate={formValues.endDate}
                  handleDateChange={(newDate) => dispatch(change('eventEditModalForm', 'endDate', newDate ? moment(newDate) : null))}
                  component={renderDatePicker}
                />
              </div>
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Event Daily Budget Amount in {selectedSeller.currencyCode}</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <CurrencyUsdIcon />
              </div>
              <Field
                name="eventDailyBudgetAmount"
                type="text"
                component={renderFieldForm}
                format={cleanNumber}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Allowed Days of the Week</span>
            {activeDayOptions && activeDayOptions.map((day) => (
              <div className="form__form-group-field">
                <Field
                  name={day.name}
                  component={renderCheckBoxField}
                  label={day.label}
                />
              </div>
            ))}
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Acknowledgement</span>
            <div className="form__form-group-field">
              <Field
                name="eventRampupApproval"
                component={renderCheckBoxField}
                label="I acknowledge the event ramp up/down flow"
                disabled={currentEditRow?.event_rampup_approval === 1}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Event State</span>
            <div className="form__form-group-field">
              <Field
                name="state"
                type="text"
                component={renderSelectField}
                options={stateOptions}
              />
            </div>
          </div>
          {showError && (
            <div className="form__form-group-error">
              <br />
              Error: {showError}
            </div>
          )}
          <div className="form__form-group">
            <br />
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Button color="primary" type="submit" disabled={disableButton}>
              {!(currentEditRow && currentEditRow.budget_group_event_daily_id) ? 'Add' : 'Edit'}
            </Button>
            <Button type="button" onClick={() => setShowEditModal()}>Cancel</Button>
          </ButtonToolbar>
        </form>
      )}
      {budgetGroupOptions && budgetGroupOptions.length === 0 && (
        <div>
          <p>Budget Groups are required before you can manage Events</p>
          <br />
          <br />
          <Button type="button" onClick={changeShowEditModal}>Ok</Button>
        </div>
      )}
    </>
  );
}

EventEditModalForm.propTypes = {
  changeShowEditModal: PropTypes.func.isRequired,
};

const validate = (values) => {
  const errors = {};
  if (!values.eventName) {
    errors.eventName = 'Name field cannot be empty';
  } else if (values && values.eventName.length > 255) {
    errors.eventName = 'Max length is 255 characters';
  } else if (values.eventName.replace(/[^\-0-9A-Za-z ]/, '').length !== values.eventName.length) {
    errors.eventName = 'Only alphanumeric, spaces, and hyphons charactors are allowed';
  }

  // Must select a value from the budget group list
  if (values && !values.budgetGroupDaily?.value) {
    errors.budgetGroupDaily = 'You must select a Budget Group';
  }

  if (!values.budgetGroupEventDailyId || values.budgetGroupEventDailyId === 0) {
    if (moment(values.startDate) <= moment()) {
      errors.startDate = 'Start Date must be in the future';
    }
  }
  if (values.endDate) {
    if (moment(values.startDate) >= moment(values.endDate)) {
      errors.endDate = 'End Date must be after Start Date';
    }
  } else {
    errors.endDate = 'End Date is required';
  }

  if (values.eventDailyBudgetAmount <= 0) {
    errors.eventDailyBudgetAmount = 'Event Daily Budget Amount must be greater than 0';
  }

  if (!values.monday && !values.tuesday && !values.wednesday && !values.thursday && !values.friday && !values.saturday && !values.sunday) {
    errors.sunday = 'At least one day of the week needs to be selected';
  }

  if (!values.eventRampupApproval) {
    errors.eventRampupApproval = 'Event Ramp up/down approval is required';
  }

  if (!values.state?.value) {
    errors.state = 'State is required';
  }

  return errors;
};

export default reduxForm({
  form: 'eventEditModalForm',
  validate,
})(EventEditModalForm);
