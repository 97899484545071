import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Container, Row, Col,
} from 'reactstrap';
import moment from 'moment';
// import InsightBudgetCard from './components/BudgetCard';
import InsightPerformanceCard from './components/PerformanceCard';
import InsightAlertCard from './components/AlertCard';
// import InsightConnectionCard from './components/ConnectionCard';
import InsightNavTabs from './components/NavTabs';
import useAlertData from './components/useAlertData';
import usePerformanceData from './components/usePerformaceData';
import { when } from '../../../shared/functions';

function InsightsIndexPage({
  alertData, performanceData, loading,
}) {
  /*
    Cards (each card should have what category it is in and which platfrom it applies to):
    PERFORMANCE (Replace ADs and KWs tabs)
      - OFF active subcat brand
      - OFF new subcat brand
    ALERT
      - Eligibility INELIGIBLE
      - Eligibility ELIGIBLE
      - Availability IN_STOCK
      - Availability IN_STOCK_SCARCE
      - Availability not IN_STOCK%
      - Buy Box Drop %
      - Buy Box Zero %
      - Buy Box Improved % (?)
    BUDGET
      - Usage
      - Pacing - Paused Lost Revenue
      - Increase Recommendations
      - Test Opertutnities
      - Request Budget Forecast (email team)
    CONNECTIONS
      - Limitless Work Done
      - Add DSP
      - Add Walmart
      - Add Marketplace (AMZ)
  */

  const [activeTab, setActiveTab] = useState('All');

  return (
    <Container className="dashboard">
      <InsightNavTabs
        alertCount={alertData.alertCount}
        performanceCount={performanceData.performanceCount}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {activeTab && (activeTab === 'All' || activeTab === 'Alerts') && (
        <Row>
          {alertData && alertData.alertCards && alertData.alertCards.map(alertCard => (
            <Col md={6} key={alertCard.title}>
              <InsightAlertCard
                loading={loading}
                title={alertCard.title}
                cardData={alertCard}
              />
            </Col>
          ))}
        </Row>
      )}
      {activeTab && (activeTab === 'All' || activeTab === 'Performance') && (
        <Row>
          {performanceData && performanceData.performanceCards && performanceData.performanceCards.map(perfromnceCard => (
            <Col md={6} key={perfromnceCard.title}>
              <InsightPerformanceCard
                loading={loading}
                title={perfromnceCard.title}
                cardData={perfromnceCard}
              />
            </Col>
          ))}
        </Row>
      )}
      {/* <Row>
        <Col md={6}>
          <InsightAlertCard
            loading={loading}
            title="Eligibility Alert"
            cardData={data.}
          />
        </Col>
        <Col md={6}>
          <InsightAlertCard
            loading={loading}
            title="Buy Box Alert"
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <InsightBudgetCard
            loading={loading}
            title="Budget Usage"
          />
        </Col>
        <Col md={6}>
          <InsightBudgetCard
            loading={loading}
            title="Budget Pacing - Paused Lost Revenue"
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <InsightBudgetCard
            loading={loading}
            title="Budget Increase Recommendations"
          />
        </Col>
        <Col md={6}>
          <InsightBudgetCard
            loading={loading}
            title="Budget Test Opertutnities"
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <InsightConnectionCard
            loading={loading}
            title="Add Walmart"
          />
        </Col>
        <Col md={6}>
          <InsightConnectionCard
            loading={loading}
            title="Add Marketplace"
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <InsightConnectionCard
            loading={loading}
            title="Add DSP"
          />
        </Col>
        <Col md={6}>
          <InsightBudgetCard
            loading={loading}
            title="Limitless Work Done"
          />
        </Col>
      </Row> */}

      <Row>
        <div><b>Last Updated</b>: {moment().format('MM/DD/YYYY')}</div>
      </Row>
    </Container>
  );
}

InsightsIndexPage.defaultProps = {
  loading: false,
};

InsightsIndexPage.propTypes = {
  loading: PropTypes.bool,
};

export default function InsightDashboard() {
  const sellerSelect = useSelector(state => state.sellerSelect);
  const [alertLoading, alertResults, alertError] = useAlertData(sellerSelect);
  const [performanceLoading, performanceResults, performanceError] = usePerformanceData(sellerSelect);
  const loading = alertLoading || performanceLoading;

  useEffect(() => {
    when(alertError, () => {
      console.error(alertError);
    });
    when(performanceError, () => {
      console.error(performanceError);
    });
  }, [alertError, performanceError]);

  // useEffect(() => { // Do I need this to check for account chnage?
  //   if (sellerSelect.value)
  //     console.error(error);
  //   });
  // }, [sellerSelect]);

  return (
    <InsightsIndexPage
      alertData={alertResults}
      performanceData={performanceResults}
      loading={loading}
    />
  );
}
