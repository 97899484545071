import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, change, getFormValues } from 'redux-form';
import classNames from 'classnames';
import { Button, ButtonToolbar } from 'reactstrap';
import Picker from 'rsuite/DatePicker';
import lodash from 'lodash';
import moment from 'moment';
import PercentIcon from 'mdi-react/PercentIcon';
import PoundIcon from 'mdi-react/PoundIcon';
import CurrencyUsdIcon from 'mdi-react/CurrencyUsdIcon';
import renderSelectField from '../../../../../shared/components/form/Select';
import renderCheckBoxField from '../../../../../shared/components/form/CheckBox';
import Tooltip from '../../../../../shared/components/widgets/Tooltip';

const renderFieldForm = ({
  input, placeholder, type, isDisabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} disabled={isDisabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderFieldForm.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  isDisabled: PropTypes.string,
  value: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderFieldForm.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  isDisabled: '',
};

const renderTextArea = ({ input, meta: { touched, error } }) => (
  <div style={{ width: '100%' }}>
    <label htmlFor="account_settings_form" style={{ width: '100%' }}>
      <div style={{ width: '100%' }}>
        <textarea
          {...input}
          rows="10"
          style={{
            width: '100%',
          }}
        />
        {touched && error && <span className="form__form-group-error">{error}</span>}
      </div>
    </label>
  </div>
);

const renderDatePicker = ({
  input, handleDateChange, meta: { touched, error },
}) => {
  const [pickerId] = useState(lodash.uniqueId('date-picker-'));
  const theme = useSelector(store => store.theme);
  const pickerClasses = classNames(theme.className, theme.className.endsWith('light') ? 'rs-theme-light' : 'rs-theme-dark');
  return (
    <div id={pickerId} style={{ position: 'absolute' }} className={pickerClasses}>
      <Picker
        value={input.value ? new Date(input.value) : null}
        onChange={handleDateChange}
        container={document.getElementById(pickerId)}
        format="yyyy-MM-dd"
        oneTap
      />
      {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
  )
};

renderDatePicker.propTypes = {
  input: PropTypes.shape().isRequired,
  handleDateChange: PropTypes.func.isRequired,
};

const ItemEditModalForm = ({
  handleSubmit, currentEditRow, changeShowEditModal, activeDayOptions, monthOptions, user, budgetAccountData, disableButton, setShowEditModal,
}) => {
  const stateOptions = [
    { label: 'Enabled', value: 'enabled' },
    { label: 'Disabled', value: 'disabled' },
    { label: 'Draft', value: 'draft' },
    { label: 'Completed', value: 'completed' },
  ];

  const setupFlowOptions = [
    { label: 'Quick Setup', value: 'quick' },
    { label: 'Advanced Setup', value: 'advanced' },
  ];

  const agencyInternalOptions = [
    { label: 'Agency View', value: false },
    { label: 'Limitless View', value: true },
  ];

  const budgetDriverOptions = [
    { label: 'Daily', value: 'daily' },
    { label: 'Monthly', value: 'monthly' },
  ];

  const allowedAdTypesOptions = [
    { label: 'Sponsored Product and Sponsored Display', value: 'SP,SD' },
    { label: 'Sponsored Product', value: 'SP' },
    { label: 'Sponsored Display', value: 'SD' },
  ];

  const budgetTypeOptions = [
    { label: 'Strict Budget', value: 0 },
    { label: 'No Budget', value: 1 },
    { label: 'Flexible Budget', value: 2 },
  ];

  const includesOptions = [
    { label: 'All ADs', value: '' },
    { label: 'Limitless ADs', value: 'limitless' },
  ];

  const spendPriorityOptions = [
    { label: 'Most Brand', value: '-2' },
    { label: 'More Brand', value: '-1' },
    { label: 'Brand and Non-Brand', value: '0' },
    { label: 'More NonBrand', value: '1' },
    { label: 'Most NonBrand', value: '2' },
  ];

  const spendTacticOptions = [
    { label: 'Max Performance', value: 'max_perf' },
    { label: 'Full Budget Spend', value: 'full_spend' },
  ];

  const spendSpeedOptions = [
    { label: 'Standard', value: 'speed_level_1' },
    { label: 'Fast', value: 'speed_level_2' },
    { label: 'Fastest', value: 'speed_level_3' },
  ];

  const costTargetTypeOptions = [
    { label: 'Blended', value: 'blended' },
    { label: 'Brand/NonBrand', value: 'bnb' },
  ];

  function cleanNumber(value) {
    if (value) {
      return value.toString().match(/^[0-9]*(?:\.\d{0,2})?/)[0];
    }
    return value;
  }

  function initFormatTwoPlaces(value) {
    if (value) {
      return (((value || 0) * 100) / 100).toFixed(2);
    }
    return value;
  }

  function itemCsvInput(value) {
    if (value) {
      return value
        .toString()
        .replace(/ /g, '')
        .replace(/\r/g, '')
        .split('\n')
        .join(',');
    }
    return value;
  }

  const selectedSeller = useSelector(store => store.sellerSelect);
  const formValues = useSelector(state => getFormValues('budgetGroupEditModal')(state));
  const { setupFlow, agencyInternal, budgetDriver, costTargetType } = formValues;

  const dispatch = useDispatch();
  const initDropdown = (name, value, options, position = 0) => {
    dispatch(change('budgetGroupEditModal', name, value ? options.find(option => option.value === value) : options[position]));
  }
  useEffect(() => {
    dispatch(change('budgetGroupEditModal', 'monthOptions', monthOptions));
    dispatch(change('budgetGroupEditModal', 'accountType', selectedSeller.type));
    dispatch(change('budgetGroupEditModal', 'budgetGroupDailyId', currentEditRow.budget_group_daily_id));
    dispatch(change('budgetGroupEditModal', 'budgetGroupName', currentEditRow.budget_group_name));
    dispatch(change('budgetGroupEditModal', 'setupFlow', currentEditRow.form_type ? setupFlowOptions.find(option => option.value === currentEditRow.form_type) : setupFlowOptions[0]));
    dispatch(change('budgetGroupEditModal', 'agencyInternal', agencyInternalOptions[0]));
    if (currentEditRow.active_days_csv) {
      const activeDays = currentEditRow.active_days_csv.split(',');
      activeDays.forEach((day, pos) => {
        const activeDayObj = activeDayOptions.find(dayObj => dayObj.value === pos.toString());
        dispatch(change('budgetGroupEditModal', activeDayObj.name, day === '1'));
      });
    }
    initDropdown('allowedAdTypes', currentEditRow.allowed_ad_types, allowedAdTypesOptions, 0);
    dispatch(change('budgetGroupEditModal', 'portfolioIdsCsv', currentEditRow.portfolio_ids_csv));
    initDropdown('includes', currentEditRow.includes, includesOptions, 1);
    dispatch(change('budgetGroupEditModal', 'costTargetType', currentEditRow.cost_target_type));
    dispatch(change('budgetGroupEditModal', 'costTargetMetric', budgetAccountData?.cost_metric_view));
    dispatch(change('budgetGroupEditModal', 'costTarget', initFormatTwoPlaces((parseFloat(currentEditRow.cost_target_brand || 0) + parseFloat(currentEditRow.cost_target_nonbrand || 0)) / 2)));
    dispatch(change('budgetGroupEditModal', 'costTargetBrand', initFormatTwoPlaces((currentEditRow.cost_target_brand || 0))));
    dispatch(change('budgetGroupEditModal', 'costTargetNonBrand', initFormatTwoPlaces((currentEditRow.cost_target_nonbrand || 0))));
    initDropdown('spendSpeed', currentEditRow.spend_speed, spendSpeedOptions, 0);
    initDropdown('spendTactic', currentEditRow.spend_tactic, spendTacticOptions, 0);
    initDropdown('budgetType', currentEditRow.limitless_budget, budgetTypeOptions, 0);
    initDropdown('spendPriority', currentEditRow.spend_priority, spendPriorityOptions, 2);
    dispatch(change('budgetGroupEditModal', 'masterItemListOrig', currentEditRow.master_item_csv));
    dispatch(change('budgetGroupEditModal', 'masterItemList', currentEditRow.master_item_csv));
    dispatch(change('budgetGroupEditModal', 'startDate', currentEditRow.start_date ? moment(currentEditRow.start_date) : moment().add(1, 'day')));
    dispatch(change('budgetGroupEditModal', 'endDate', currentEditRow.end_date ? moment(currentEditRow.end_date) : null));
    initDropdown('budgetDriver', currentEditRow.budget_driver, budgetDriverOptions, 0);
    initDropdown('costTargetType', currentEditRow.cost_target_type, costTargetTypeOptions, 0);
    if (currentEditRow.budget_json) {
      const budgetJson = JSON.parse(currentEditRow.budget_json);
      if (budgetJson.monthly) {
        Object.keys(budgetJson.monthly).forEach((monthNum) => {
          dispatch(change('budgetGroupEditModal', budgetJson.monthly[monthNum].name.toLowerCase(), initFormatTwoPlaces(budgetJson.monthly[monthNum].budget)));
        });
      }
      if (budgetJson.daily) {
        dispatch(change('budgetGroupEditModal', 'baseDailyBudgetAmount', initFormatTwoPlaces(budgetJson.daily?.budget)));
        dispatch(change('budgetGroupEditModal', 'reallocation', initFormatTwoPlaces(budgetJson.daily?.reallocation)));
      }
    }
    dispatch(change('budgetGroupEditModal', 'state', currentEditRow.state ? stateOptions.find(option => option.value === currentEditRow.state) : stateOptions[0]));
  }, []);

  useEffect(() => {
    if (currentEditRow.active_days_csv) {
      const activeDays = currentEditRow.active_days_csv.split(',');
      activeDays.forEach((day, pos) => {
        const activeDayObj = activeDayOptions.find(dayObj => dayObj.value === pos.toString());
        dispatch(change('budgetGroupEditModal', activeDayObj.name, day === '1'));
      });
    } else {
      activeDayOptions.forEach((day) => {
        dispatch(change('budgetGroupEditModal', day.name, true));
      });
    }
  }, [setupFlow]);

  return (
    <>
      {budgetAccountData.cost_metric_view && (
        <form className="form" onSubmit={handleSubmit}>
          <h2>Manage Budget Groups</h2>
          <div className="form__form-group">
            <br />
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label typography-message">Budget Group Name <Tooltip id="budgetGroupName" msg="Case ensitive and must be unique" placement="top" /></span>
            <div className="form__form-group-field">
              <Field
                name="budgetGroupName"
                type="text"
                component={renderFieldForm}
                required
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Budget Group Setup Flow</span>
            <div className="form__form-group-field">
              <Field
                name="setupFlow"
                type="text"
                component={renderSelectField}
                options={setupFlowOptions}
              />
            </div>
          </div>
          {user.access === 'admin' && (
            <div className="form__form-group">
              <span className="form__form-group-label">View Toggle</span>
              <div className="form__form-group-field">
                <Field
                  name="agencyInternal"
                  type="text"
                  component={renderSelectField}
                  options={agencyInternalOptions}
                  defaultValue={agencyInternal}
                />
              </div>
            </div>
          )}
          {(setupFlow?.value || 'advanced') === 'advanced' && (
            <div className="form__form-group">
              <span className="form__form-group-label">Allowed Days of the Week</span>
              {activeDayOptions && activeDayOptions.map((day) => (
                <div className="form__form-group-field">
                  <Field
                    name={day.name}
                    component={renderCheckBoxField}
                    label={day.label}
                  />
                </div>
              ))}
            </div>
          )}
          {setupFlow?.value === 'advanced' && agencyInternal?.value && (
            <div className="form__form-group">
              <span className="form__form-group-label">Allowed Ad Types</span>
              <div className="form__form-group-field">
                <Field
                  name="allowedAdTypes"
                  type="text"
                  component={renderSelectField}
                  options={allowedAdTypesOptions}
                  value={allowedAdTypesOptions[0]}
                />
              </div>
            </div>
          )}
          {setupFlow?.value === 'advanced' && agencyInternal?.value && (
            <div className="form__form-group">
              <span className="form__form-group-label typography-message">Portfolio(s)</span>
              <div className="form__form-group-field">
                <Field
                  name="portfolioIdsCsv"
                  type="text"
                  component={renderFieldForm}
                />
              </div>
            </div>
          )}
          {setupFlow?.value === 'advanced' && agencyInternal?.value && (
            <div className="form__form-group">
              <span className="form__form-group-label">Includes</span>
              <div className="form__form-group-field">
                <Field
                  name="includes"
                  type="text"
                  component={renderSelectField}
                  options={includesOptions}
                />
              </div>
            </div>
          )}
          <div className="form__form-group">
            <span className="form__form-group-label">Cost Target Type</span>
            <div className="form__form-group-field">
              <Field
                name="costTargetType"
                type="text"
                component={renderSelectField}
                options={costTargetTypeOptions}
              />
            </div>
          </div>
          {costTargetType?.value === 'blended' && (
            <div className="form__form-group">
              <span className="form__form-group-label typography-message">Cost Target ({budgetAccountData?.cost_metric_view?.toUpperCase().replace('PACOS', 'PACoS')})</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  {budgetAccountData?.cost_metric_view === 'roas' && (
                    <PoundIcon />
                  )}
                  {budgetAccountData?.cost_metric_view === 'pacos' && (
                    <PercentIcon />
                  )}
                </div>
                <Field
                  name="costTarget"
                  type="text"
                  component={renderFieldForm}
                  // parse={cleanNumber}
                />
              </div>
              {budgetAccountData && currentEditRow && currentEditRow.cost_target_metric && budgetAccountData?.cost_metric_view !== currentEditRow.cost_target_metric && <span className="form__form-group-warn">Account Metric is differnet than saved Group Metric.</span>}
            </div>
          )}
          {costTargetType?.value === 'bnb' && (
            <div className="form__form-group">
              <span className="form__form-group-label">Brand Cost Target ({budgetAccountData?.cost_metric_view.toUpperCase().replace('PACOS', 'PACoS')})</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  {budgetAccountData?.cost_metric_view === 'roas' && (
                    <PoundIcon />
                  )}
                  {budgetAccountData?.cost_metric_view === 'pacos' && (
                    <PercentIcon />
                  )}
                </div>
                <Field
                  name="costTargetBrand"
                  type="text"
                  component={renderFieldForm}
                  format={cleanNumber}
                />
              </div>
              {budgetAccountData && currentEditRow && currentEditRow.cost_target_metric && budgetAccountData?.cost_metric_view !== currentEditRow.cost_target_metric && <span className="form__form-group-warn">Account Metric is differnet than saved Group Metric.</span>}
            </div>
          )}
          {costTargetType?.value === 'bnb' && (
            <div className="form__form-group">
              <span className="form__form-group-label">Non-Brand Cost Target ({budgetAccountData?.cost_metric_view.toUpperCase().replace('PACOS', 'PACoS')})</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  {budgetAccountData?.cost_metric_view === 'roas' && (
                    <PoundIcon />
                  )}
                  {budgetAccountData?.cost_metric_view === 'pacos' && (
                    <PercentIcon />
                  )}
                </div>
                <Field
                  name="costTargetNonBrand"
                  type="text"
                  component={renderFieldForm}
                  format={cleanNumber}
                />
              </div>
              {budgetAccountData && currentEditRow && currentEditRow.cost_target_metric && budgetAccountData?.cost_metric_view !== currentEditRow.cost_target_metric && <span className="form__form-group-warn">Account Metric is differnet than saved Group Metric.</span>}
            </div>
          )}
          {setupFlow?.value === 'advanced' && agencyInternal?.value && (
            <div className="form__form-group">
              <span className="form__form-group-label">Spend Speed</span>
              <div className="form__form-group-field">
                <Field
                  name="spendSpeed"
                  type="text"
                  component={renderSelectField}
                  options={spendSpeedOptions}
                />
              </div>
            </div>
          )}
          {setupFlow?.value === 'advanced' && agencyInternal?.value && (
            <div className="form__form-group">
              <span className="form__form-group-label">Spend Tactic</span>
              <div className="form__form-group-field">
                <Field
                  name="spendTactic"
                  type="text"
                  component={renderSelectField}
                  options={spendTacticOptions}
                />
              </div>
            </div>
          )}
          {setupFlow?.value === 'advanced' && agencyInternal?.value && (
            <div className="form__form-group">
              <span className="form__form-group-label">Budget Type</span>
              <div className="form__form-group-field">
                <Field
                  name="budgetType"
                  type="text"
                  component={renderSelectField}
                  options={budgetTypeOptions}
                />
              </div>
            </div>
          )}
          <div className="form__form-group">
            <span className="form__form-group-label">Spend Priority</span>
            <div className="form__form-group-field">
              <Field
                name="spendPriority"
                type="text"
                component={renderSelectField}
                options={spendPriorityOptions}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Budget Group Master Item List (<a href="/agency/budget-awl#Items" target="_blank" rel="noopener noreferrer">View Catalog HERE</a>)</span>
            <div className="form__form-group-field">
              <Field
                name="masterItemList"
                type="text"
                component={renderTextArea}
                format={itemCsvInput}
                required
              />
            </div>
          </div>
          <div className="form__form-group">
            <div style={{ width: '50%', height: '60px', float: 'left' }}>
              <span className="form__form-group-label">Start Date</span>
              <div>
                <Field
                  name="startDate"
                  type="text"
                  // setDate={startDate}
                  handleDateChange={(newDate) => dispatch(change('budgetGroupEditModal', 'startDate', newDate ? moment(newDate) : null))}
                  component={renderDatePicker}
                  required
                />
              </div>
            </div>
            <div style={{ marginLeft: '50%', height: '60px' }}>
              <span className="form__form-group-label">End Date</span>
              <div className="form__form-group-field">
                <Field
                  name="endDate"
                  type="text"
                  // setDate={formValues.endDate}
                  handleDateChange={(newDate) => dispatch(change('budgetGroupEditModal', 'endDate', newDate ? moment(newDate) : null))}
                  component={renderDatePicker}
                />
              </div>
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Budget Driver</span>
            <div className="form__form-group-field">
              <Field
                name="budgetDriver"
                type="text"
                component={renderSelectField}
                options={budgetDriverOptions}
              />
            </div>
          </div>
          {budgetDriver?.value === 'daily' && (
            <div className="form__form-group">
              <span className="form__form-group-label">Total Daily Budget Amount in {selectedSeller.currencyCode} for all Items</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <CurrencyUsdIcon />
                </div>
                <Field
                  name="baseDailyBudgetAmount"
                  type="text"
                  component={renderFieldForm}
                  format={cleanNumber}
                />
              </div>
            </div>
          )}
          {budgetDriver?.value === 'monthly' && (
            <div className="form__form-group">
              <span className="form__form-group-label">Monthly Budget Amount in {selectedSeller.currencyCode}</span>
              {monthOptions && monthOptions.map((month) => (
                <div className="form__form-group-field" style={{ padding: '2px' }}>
                  <span style={{ paddingRight: '10px', paddingTop: '5px', width: '25%', textAlign: 'right' }}>
                    {month.label}:
                  </span>
                  <div className="form__form-group-icon">
                    <CurrencyUsdIcon />
                  </div>
                  <Field
                    name={month.name}
                    type="text"
                    component={renderFieldForm}
                    format={cleanNumber}
                  />
                </div>
              ))}
            </div>
          )}
          {setupFlow?.value === 'advanced' && agencyInternal?.value && (
            <div className="form__form-group">
              <span className="form__form-group-label">Total Daily Reallocation Budget Amount in {selectedSeller.currencyCode}</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <CurrencyUsdIcon />
                </div>
                <Field
                  name="reallocation"
                  type="text"
                  component={renderFieldForm}
                  format={cleanNumber}
                />
              </div>
            </div>
          )}
          <div className="form__form-group">
            <span className="form__form-group-label">Budget Group State</span>
            <div className="form__form-group-field">
              <Field
                name="state"
                type="text"
                component={renderSelectField}
                options={stateOptions}
              />
            </div>
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Button color="primary" type="submit" disabled={disableButton}>
              {!(currentEditRow && currentEditRow.budget_group_daily_id) ? 'Add' : 'Edit'}
            </Button>
            <Button type="button" onClick={() => setShowEditModal(false)}>Cancel</Button>
          </ButtonToolbar>
        </form>
      )}
      {!budgetAccountData.cost_metric_view && (
        <div>
          <p>Budget Account Setting are required before you can manage Budget Groups</p>
          <br />
          <br />
          <Button type="button" onClick={changeShowEditModal}>Ok</Button>
        </div>
      )}
    </>
  )
};

ItemEditModalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  currentEditRow: PropTypes.shape(),
  changeShowEditModal: PropTypes.func.isRequired,
  activeDayOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  monthOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  user: PropTypes.shape().isRequired,
};

ItemEditModalForm.defaultProps = {
  currentEditRow: null,
};

const validate = (values) => {
  const errors = {};
  // All fields are requried!

  // *Budget Group Name (Phase 1)
  // values.budgetGroupName = values.budgetGroupName?.replace(/[^\-0-9A-Za-z ]/, '');
  if (!values.budgetGroupName) {
    errors.budgetGroupName = 'Name field cannot be empty';
  } else if (values && values.budgetGroupName.length > 255) {
    errors.budgetGroupName = 'Max length is 255 characters';
  } else if (values.budgetGroupName.replace(/[^\-0-9A-Za-z ]/, '').length !== values.budgetGroupName.length) {
    errors.budgetGroupName = 'Only alphanumeric, spaces, and hyphons charactors are allowed';
  }
  // + add no special charactors A-Za-z0-9 and -
  // + add no dups
  // TOOL TIP: Case Sensitive

  // *Allowed Days of the Week.
  // + min of one day required
  // UPDATE: Let's do Monday to Sunday
  if (!values.monday && values.tuesday && values.wednesday && values.thursday && values.friday && values.saturday && values.sunday) {
    errors.daysOfTheWeek = 'At least one day of the week needs to be selected';
  }

  // *Portfolios
  // + Load the Account Default  (low prirority)

  // *Includes
  // - only accept 'Limitless Ads' at this stage
  if (values.includes?.value === '') {
    errors.includes = 'Includes only allows for Limitless ADs';
  }

  // *Cost Target - this works for bnb as well
  // Update the name to show ACoS or ROAS as selected
  // Show and Export data as selected
  // Always save as ACoS though
  // Show as ACoS (Percent) and ROAS (Number) Whole number with two digits
  // + Min/Max ACoS (5% and 300%)
  // + Display ROAS based on setting (.333 to 20)
  // UDPATE: use account setting to deterime if it's ROAD or ACoS. but always save as ACoS

  if (values.costTargetMetric === 'pacos') {
    if (values.costTargetType.value === 'blended' && (values.costTarget < 5 || values.costTarget > 300)) {
      errors.costTarget = 'Cost Target for PACoS must be between 5 and 300';
    }
    if (values.costTargetType.value === 'bnb' && (values.costTargetBrand < 5 || values.costTargetBrand > 300)) {
      errors.costTargetBrand = 'Brand Cost Target for PACoS must be between 5 and 300';
    }
    if (values.costTargetType.value === 'bnb' && (values.costTargetNonBrand < 5 || values.costTargetNonBrand > 300)) {
      errors.costTargetNonBrand = 'Non-Brand Cost Target for PACoS must be between 5 and 300';
    }
  }
  if (values.costTargetMetric === 'roas') {
    if (values.costTargetType.value === 'blended' && (values.costTarget < 0.333 || values.costTarget > 20)) {
      errors.costTarget = 'Cost Target for ROAS must be between 0.333 and 20';
    }
    if (values.costTargetType.value === 'bnb' && (values.costTargetBrand < 0.333 || values.costTargetBrand > 20)) {
      errors.costTargetBrand = 'Brand Cost Target for ROAS must be between 0.333 and 20';
    }
    if (values.costTargetType.value === 'bnb' && (values.costTargetNonBrand < 0.333 || values.costTargetNonBrand > 20)) {
      errors.costTargetNonBrand = 'Non-Brand Cost Target for ROAS must be between 0.333 and 20';
    }
  }

  // *Master Item List (Phase 1)
  // + Download catalog button
  // + Change to text box, and wrap text, scroll as needed.
  // - Remove spaces
  // + add the ability to do new line or csv
  // Seller can't start with B0, Vendors MUST start with B0
  // TOOL TIP: This will move items assigned to other Budget Groups in to this one if inserted here.
  if (values.masterItemList) {
    let foundB0 = false;
    const itemList = values.masterItemList.split(',');
    itemList.forEach(item => {
      if (item?.toUpperCase().startsWith('B0')) {
        foundB0 = true;
      }
    });
    if (foundB0 && values.accountType === 1) {
      errors.masterItemList = 'Seller SKUs cannot start with B0';
    }
    if (!foundB0 && values.accountType === 2) {
      errors.masterItemList = 'Vendor ASINs must start with B0';
    }
  } else {
    errors.masterItemList = 'Items are required';
  }

  // *Dates
  // + New BG, start date must be in the future
  // + Existing BG, you can't edit the start date once it's past that date.
  // + End date cannnot be before or equal the start date.
  // + Date range mush incluide at least one active day (in the future).
  // + What timezone??????
  if (!values.budgetGroupDailyId || values.budgetGroupDailyId === 0) {
    if (moment(values.startDate) <= moment()) {
      errors.startDate = 'Start Date must be in the future';
    }
  }
  if (values.endDate) {
    if (moment(values.startDate) >= moment(values.endDate)) {
      errors.endDate = 'End Date must be after Start Date';
    }
  }

  // *Amounts (daily/montly)
  // + Add local currency

  // *Monthly Budget Amount (Phase 3)
  // + noof active day count for each month.
  // + calculate avd using above
  // + confirm we have a min of $1 per item per day
  // + All Months must be filled out (Phase 1)
  if (values.budgetDriver?.value === 'monthly') {
    values.monthOptions.forEach(month => {
      if (!values[month.name] || values[month.name] <= 0) {
        errors[month.name] = 'Month budget must be set';
      }
    });
  }
  if (values.budgetDriver?.value === 'daily') {
    if (!values.baseDailyBudgetAmount || values.baseDailyBudgetAmount <= 0) {
      errors.baseDailyBudgetAmount = 'Daily budget must be set';
    }
  }


  // If account metric doesn't match Group metric, make sure they change the value.

  return errors;
};

export default reduxForm({
  form: 'budgetGroupEditModal',
  validate,
})(ItemEditModalForm);
