// import moment from 'moment';
import config from '../../config';
import { get, post } from './base/index';

export default {
  getTodoData: (data, header) => new Promise((resolve) => {
    const params = { ...data };
    resolve(get(`${config.serverUrl}/management/todo`, { params }, header));
  }),
  setTodoData: (body, data, header) => new Promise((resolve) => {
    const params = { ...data };
    resolve(post(`${config.serverUrl}/management/todo`, body, { params }, header));
  }),
};
