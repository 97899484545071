import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Picker from 'rsuite/DatePicker';
import lodash from 'lodash';
import classNames from 'classnames';

export default function DatePicker(props) {
  const {
    label, theDate, onDateChange,
  } = props;

  const [pickerId] = useState(lodash.uniqueId('date-picker-'));
  const [loaded, setLoaded] = useState(false);
  const theme = useSelector(store => store.theme);
  const pickerClasses = classNames(theme.className, 'topbar__dynamicDropdownWidth', theme.className.endsWith('light') ? 'rs-theme-light' : 'rs-theme-dark');

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  })

  const yesterday = moment().subtract(1, 'day');

  return (
    <>
      {label && (
        <p>{label}</p>
      )}
      <div id={pickerId} className={pickerClasses}>
        <Picker
          defaultValue={theDate || yesterday.toDate()}
          onChange={date => (date ? onDateChange(date, date) : null)}
          container={document.getElementById(pickerId)}
          format="yyyy-MM-dd"
          oneTap
        />
      </div>
    </>
  )
}
