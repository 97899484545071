import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row } from 'reactstrap';
import moment from 'moment';
import EventEditModal from './form/EventEditModal';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import { currencyFormatter, getExportPrefix } from '../../../../shared/components/table/functions';

export default function BudgetEventsTable({ retailerConfig, forceRefresh, setForceRefresh, activeDayOptions, budgetGroupOptions }) {
  const sellerSelect = useSelector(store => store.sellerSelect);
  const theme = useSelector(store => store.theme);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editRowData, setEditRowData] = useState({});
  const [agGrid, setAgGrid] = useState(null);

  const readableActiveDays = (activeDaysCsv) => {
    const readableArray = [];
    const activeDaysArray = activeDaysCsv?.split(',') || [];
    activeDaysArray.forEach((day, pos) => {
      if (day === '1') {
        readableArray.push(moment().isoWeekday(pos + 1).format('dddd'));
      }
    });
    return readableArray.join(', ');
  }

  const changeShowEditModal = (data, forceState) => {
    setEditRowData(data);
    setShowEditModal(forceState || !showEditModal);
  };

  const columnDefs = [
    {
      field: 'editEvents',
      headerName: 'Events',
      editable: false,
      cellRenderer: (params) => {
        if (params.node?.footer) {
          return '';
        }
        return <button type="submit" onClick={() => changeShowEditModal(params.data)}>Edit Event</button>;
      },
    },
    {
      field: 'state',
      headerName: 'State',
      valueFormatter: (params) => {
        if (params.value) {
          return params.value.charAt(0).toUpperCase() + params.value.slice(1);
        }
        return params.value;
      },
    },
    { field: 'budget_group_name', headerName: 'Budget Group' },
    { field: 'event_name', headerName: 'Event Name' },
    { field: 'event_daily_budget_amount', headerName: 'Event Budget Amount', valueFormatter: currencyFormatter },
    { field: 'start_date', headerName: 'Start Date' },
    { field: 'end_date', headerName: 'End Date' },
    { field: 'active_days_csv', headerName: 'Active Days', valueFormatter: (row) => readableActiveDays(row.data.active_days_csv) },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  const showExportOptions = true;
  const apiEndPoint = `${config.serverUrl}/accounts/budget-group-event-daily?retailer=${retailerConfig.retailerKey}`;

  const exportPrefix = getExportPrefix(`${retailerConfig.retailerPrefix}-budget-group-events`, sellerSelect, true);

  function onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  const parentAccess = (agGridPointer) => {
    setAgGrid(agGridPointer);
  }

  useEffect(() => {
    if (agGrid) {
      agGrid.refreshData();
    }
  }, [forceRefresh]);

  const customButton = {
    id: 'addButton',
    function: () => changeShowEditModal(),
    text: 'Add Event',
  }

  return (
    <Container>
      <EventEditModal
        changeShowEditModal={changeShowEditModal}
        currentEditRow={editRowData}
        showEditModal={showEditModal}
        theme={theme}
        setForceRefresh={setForceRefresh}
        activeDayOptions={activeDayOptions}
        budgetGroupOptions={budgetGroupOptions}
        setShowEditModal={setShowEditModal}
      />
      <Row>
        <TableBuilder
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          apiEndPoint={apiEndPoint}
          showDatePicker={false}
          showExportOptions={showExportOptions}
          exportPrefix={exportPrefix}
          onFirstDataRendered={onFirstDataRendered}
          parentAccess={parentAccess}
          customButton={customButton}
        />
      </Row>
    </Container>
  );
}
