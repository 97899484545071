import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Nav, NavItem, NavLink,
} from 'reactstrap';
import classnames from 'classnames';

export default function BudgetGroupNavTabs({
  activeTab, setActiveTab,
}) {
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  return (
    <Col md={12} lg={12}>
      <div className="profile__card tabs tabs--bordered-bottom">
        <div className="tabs__wrap">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'Account' })}
                onClick={() => {
                  toggle('Account');
                }}
              >
                <span className={'sidebar__link-icon lnr lnr-cog'} /> Budget Account Settings <span style={{ paddingLeft: '30px' }}> </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'Groups' })}
                onClick={() => {
                  toggle('Groups');
                }}
              >
                <span className={'sidebar__link-icon lnr lnr-layers'} /> Budget Groups <span style={{ paddingLeft: '30px' }}> </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'Events' })}
                onClick={() => {
                  toggle('Events');
                }}
              >
                <span className={'sidebar__link-icon lnr lnr-calendar-full'} /> Budget Events <span style={{ paddingLeft: '30px' }}> </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'Items' })}
                onClick={() => {
                  toggle('Items');
                }}
              >
                <span className={'sidebar__link-icon lnr lnr-store'} /> Budget Items <span style={{ paddingLeft: '30px' }}> </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'Growth' })}
                onClick={() => {
                  toggle('Growth');
                }}
              >
                <span className={'sidebar__link-icon lnr lnr-chart-bars'} /> Budget Growth <span style={{ paddingLeft: '30px' }}> </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'Forecast' })}
                onClick={() => {
                  toggle('Forecast');
                }}
              >
                <span className={'sidebar__link-icon lnr lnr-chart-bars'} /> Budget Forecast <span style={{ paddingLeft: '30px' }}> </span>
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </div>
    </Col>
  );
}

BudgetGroupNavTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};
