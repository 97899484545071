import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import useData from './useData';
import Panel from '../../../../../shared/components/Panel';
import Notify from '../../../../../shared/components/widgets/Notify';

export default function AccountStatusWithContext() {
  const selectedSeller = useSelector(store => store.sellerSelect);
  const [loading, results, error] = useData(selectedSeller);

  const createInstance = () => {
    Notify.show('Warning', 'Started Process', 'instance create requested');
  };

  return (
    <>
      {error}
      <Panel lg={12} title="Linked AMC Instance" parentRefresh={loading}>
        <h3 className="page-subhead subhead">Coming Soon!</h3>
        <Row>
          <Col md={6} lg={6} xl={6}>
            <p>
              Account ID: {selectedSeller.value}<br />
              AMC Instance ID: {results?.instanceId}<br />
              AMC Account ID: {results?.amcAccountId}<br />
              <pre>{JSON.stringify(results?.instanceInfo, null, 2)}</pre>
            </p>
          </Col>
          <Col md={6} lg={6} xl={6}>
            {results?.instanceInfo === null && (
              <p>
                On-board AMC Instance, takes about 1 hour after clicking to complete setup.
                <br />
                <br />
                Step 1:<br />
                <button type="button" style={{ width: '50%' }} className="btn btn-primary account__btn" onClick={() => createInstance()}>Create Instance</button>
              </p>
            )}
          </Col>
        </Row>
      </Panel>
    </>
  );
}
