import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import GroupEditModalForm from './GroupEditModalForm';
import config from '../../../../../config';
import Notify from '../../../../../shared/components/widgets/Notify';

const ItemEditModal = ({ currentEditRow, changeShowEditModal, showEditModal, setForceRefresh, budgetAccountData, activeDayOptions, disableButton, setDisableButton, setShowEditModal }) => {
  const sellerSelect = useSelector(store => store.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  const theme = useSelector(store => store.theme);

  const monthOptions = [
    { label: 'January', name: 'january', value: '1' },
    { label: 'February', name: 'february', value: '2' },
    { label: 'March', name: 'march', value: '3' },
    { label: 'April', name: 'april', value: '4' },
    { label: 'May', name: 'may', value: '5' },
    { label: 'June', name: 'june', value: '6' },
    { label: 'July', name: 'july', value: '7' },
    { label: 'August', name: 'august', value: '8' },
    { label: 'September', name: 'september', value: '9' },
    { label: 'October', name: 'october', value: '10' },
    { label: 'November', name: 'november', value: '11' },
    { label: 'December', name: 'december', value: '12' },
  ];

  const handleSubmit = (formData) => {
    setDisableButton(true);
    const activeDaysArray = [];
    const budgetObj = {
      monthly: {},
    };

    activeDayOptions.forEach(day => {
      activeDaysArray.push(formData[day.name] ? '1' : '0');
    });

    budgetObj.daily = {
      budget: parseFloat(formData.baseDailyBudgetAmount),
      reallocation: formData.reallocation,
    }

    monthOptions.forEach(month => {
      budgetObj.monthly[month.value] = {
        name: month.label,
        budget: parseFloat(formData[month.name]) || 0,
      };
    });

    const submitBudgetGroupDaily = {
      accountId: sellerSelect.value,
      accountTypeId: sellerSelect.type,
      budgetGroupDailyId: formData.budgetGroupDailyId || 0,
      budgetGroupName: formData.budgetGroupName,
      setupFlow: formData.setupFlow?.value,
      agencyInternal: formData.agencyInternal?.value, // Only needed for display
      activeDaysCsv: activeDaysArray.join(','),
      allowedAdTypes: formData.allowedAdTypes.value,
      portfolioIdsCsv: formData.portfolioIdsCsv,
      includes: formData.includes.value,
      costTargetType: formData.costTargetType.value,
      costTargetMetric: formData.costTargetMetric,
      costTargetBrand: formData.costTargetType.value === 'bnb' ? formData.costTargetBrand : formData.costTarget,
      costTargetNonBrand: formData.costTargetType.value === 'bnb' ? formData.costTargetNonBrand : formData.costTarget,
      spendSpeed: formData.spendSpeed.value,
      spendTactic: formData.spendTactic.value,
      budgetType: formData.budgetType.value,
      spendPriority: formData.spendPriority.value,
      masterItemListOrig: formData.masterItemListOrig,
      masterItemList: formData.masterItemList,
      startDate: formData.startDate,
      endDate: formData.endDate,
      budgetDriver: formData.budgetDriver.value,
      budgetJson: budgetObj,
      state: formData.state.value,
    };

    if (formData.budgetGroupName) { // Check if there is an error.
      const postUrl = `${config.serverUrl}/accounts/budget-group-daily`;
      const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
      const requestOptions = {
        method: 'POST',
        headers: headerWithAuth,
        body: JSON.stringify(submitBudgetGroupDaily),
      };

      fetch(postUrl, requestOptions)
        .then((results) => {
          if (!results.ok) {
            throw Error(results.statusText);
          }
          return results.json();
        }).then(() => {
          changeShowEditModal();
          setDisableButton(false);
          setForceRefresh(moment().utc());
        }).catch((error) => {
          Notify.show('danger', 'Error', `We were unable to update your budget. Error: ${error}`);
          console.error(error);
          setDisableButton(false);
        });
    }
  };

  const modalClasses = classNames({
    'todo__add-modal': true,
  }, theme.className, 'ltr-support');

  return (
    <Modal
      isOpen={showEditModal}
      toggle={changeShowEditModal}
      className={modalClasses}
    >
      <GroupEditModalForm
        onSubmit={handleSubmit}
        initialValues={currentEditRow}
        // handleSubmit={handleSubmit} // Try to figure this out.  onSubmit and initalValues seem to be defualt props for redux?
        currentEditRow={currentEditRow}
        changeShowEditModal={changeShowEditModal}
        activeDayOptions={activeDayOptions}
        monthOptions={monthOptions}
        user={user}
        budgetAccountData={budgetAccountData}
        disableButton={disableButton}
        setDisableButton={setDisableButton}
        setShowEditModal={setShowEditModal}
      />
    </Modal>
  );
};

ItemEditModal.propTypes = {
  currentEditRow: PropTypes.shape(),
  changeShowEditModal: PropTypes.func.isRequired,
  showEditModal: PropTypes.bool.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
};

ItemEditModal.defaultProps = {
  currentEditRow: null,
};

export default ItemEditModal;
