import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Row } from 'reactstrap';
import config from '../../../../../config';
import TableBuilder from '../../../../../shared/components/table/TableBuilder';
import CardPanel from '../../../../../shared/components/widgets/CardPanel';
import {
  currencyFormatter, formatNumberDefaultToZero, formatNumberOnePlaceDefaultToZero,
} from '../../../../../shared/components/table/functions';

function CampaignReportTable() {
  const selectedSeller = useSelector(store => store.sellerSelect);
  const columnDefs = [
    {
      field: 'order_name',
      headerName: 'Order Name',
    },
    {
      field: 'order_start_date',
      headerName: 'Order Start Date',
    },
    {
      field: 'order_end_date',
      headerName: 'Order End Date',
    },
    {
      field: 'max_order_budget',
      headerName: 'Order Budget',
      valueFormatter: currencyFormatter,
    },
    {
      field: 'total_cost',
      headerName: 'Total Cost',
      valueFormatter: currencyFormatter,
    },
    {
      field: 'impressions',
      headerName: 'Impressions',
      valueFormatter: formatNumberDefaultToZero,
    },
    {
      field: 'click_throughs',
      headerName: 'Click Throughs',
      valueFormatter: formatNumberDefaultToZero,
    },
    {
      field: 'total_units_sold',
      headerName: 'Total Units Sold',
      valueFormatter: formatNumberDefaultToZero,
    },
    {
      field: 'total_sales',
      headerName: 'Total Sales',
      valueFormatter: currencyFormatter,
    },
    {
      field: 'roas',
      headerName: 'ROAS',
      valueFormatter: formatNumberOnePlaceDefaultToZero,
    },
    {
      field: 'total_dpv',
      headerName: 'Total DPV',
      valueFormatter: formatNumberDefaultToZero,
    },
    {
      field: 'total_dpv_views',
      headerName: 'Total DPV Views',
      valueFormatter: formatNumberDefaultToZero,
    },
    {
      field: 'total_dpv_clicks',
      headerName: 'Total DPV Clicks',
      valueFormatter: formatNumberDefaultToZero,
    },
    {
      field: 'total_purchases',
      headerName: 'Total Purchases',
      valueFormatter: formatNumberDefaultToZero,
    },
    {
      field: 'total_ntb_product_sales',
      headerName: 'NTB Product Sales',
      valueFormatter: currencyFormatter,
    },
    {
      field: 'total_ntb_units',
      headerName: 'NTB Units Sold',
      valueFormatter: formatNumberDefaultToZero,
    },

    {
      field: 'total_ntb_clicks',
      headerName: 'NTB Purchases Clicks',
      valueFormatter: formatNumberDefaultToZero,
    },
    {
      field: 'total_ntb_views',
      headerName: 'NTB Purchases Views',
      valueFormatter: formatNumberDefaultToZero,
    },
    {
      field: 'total_ntb_purchases',
      headerName: 'NTB Purchases',
      valueFormatter: formatNumberDefaultToZero,
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: false,
    resizable: true,
    cellStyle: (params) => {
      if (params && params.value < 0.00) {
        return { color: 'red' };
      }
      return null;
    },
  };

  const tableConfig = {
    showDatePicker: true,
    showFooter: false,
    apiEndPoint: `${config.serverUrl}/dsp/campaign-report/${selectedSeller.value}`,
  };

  return (
    <Container className="todo-app">
      <Row>
        <CardPanel
          autorenewButton={false}
          closeButton={false}
        >
          <TableBuilder
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            apiEndPoint={tableConfig.apiEndPoint}
            showDatePicker={tableConfig.showDatePicker}
            showFooter={tableConfig.showFooter}
          />
        </CardPanel>
      </Row>
    </Container>
  );
}

export default CampaignReportTable;
