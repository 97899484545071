import React, { useState } from 'react';
import { Tooltip as ReactTooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';

export default function Tooltip({ id, msg, placement }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <span
        style={{ color: 'gray', paddingLeft: '5px', paddingBottom: '10px' }}
        href="#"
        id={id}
      >
        <InformationOutlineIcon size="13" />
      </span>
      <ReactTooltip
        placement={placement}
        isOpen={tooltipOpen}
        autohide={false}
        target={id}
        toggle={toggle}
      >
        <span>{msg}</span>
      </ReactTooltip>
    </>
  )
}

Tooltip.defaultProps = {
  placement: 'bottom',
};

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  msg: PropTypes.string.isRequired,
  placement: PropTypes.string,
};


// export default {
//   Tooltip,
// };
