import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row } from 'reactstrap';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import {
  currencyFormatter, getExportPrefix,
} from '../../../../shared/components/table/functions';
// import { imageFieldColumn, imageCellRendererComponent } from '../../../../shared/components/table/ImageCellRenderer';
import CardPanel from '../../../../shared/components/widgets/CardPanel';
import { doFetch } from '../../../../helpers';
import { countryOptionsArray, countryOptionDefault } from '../../../Onboarding/amazon/components/OnboardingConfig';
import ItemEditModal from './form/ItemEditModal';

function SimpleCatalogTable({ forceRefresh, setForceRefresh, budgetGroupOptions }) {
  const selectedSeller = useSelector(store => store.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  const theme = useSelector(store => store.theme);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedData, setSelectedData] = useState(0);
  const [agGrid, setAgGrid] = useState(null);

  const exportPrefix = getExportPrefix('amz-catalog', selectedSeller, true);

  const columnDefs = [
    // imageFieldColumn,
    {
      field: 'asin',
      headerName: 'ASIN',
      cellRenderer: (params) => {
        if (params && params.data) {
          const getMarketPlace = (name) => {
            let result = countryOptionDefault.marketplace;
            countryOptionsArray.forEach((country) => {
              if (name === country.value) {
                result = country.marketplace;
              }
            });
            return result;
          };
          const { mpName } = selectedSeller;
          const marketplace = getMarketPlace(mpName);
          const url = `https://${marketplace}/dp/${params.data.asin}`;
          return <a href={url} target="_blank" rel="noopener noreferrer">{params.data.asin}</a>;
        }
        return '';
      },
    },
    {
      field: 'sku',
      headerName: 'SKU',
    },
    { field: 'product_title', headerName: 'Product Title' },
    {
      field: 'list_price',
      headerName: 'List Price',
      hide: true,
      valueFormatter: currencyFormatter,
    },
    { field: 'budget_group', headerName: 'Budget Group' },
    {
      field: 'ad_status',
      headerName: 'Ad Status',
    },
    {
      field: 'reporting_visibility',
      headerName: 'Reporting Visibility',
    },
    {
      field: 'brand',
      headerName: 'Brand',
      headerTooltip: 'ASIN Brand used in reports',
    },
    {
      field: 'subcategory',
      headerName: 'Subcategory',
      headerTooltip: 'ASIN Subcategory used in reports',
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: false,
    resizable: true,
    cellStyle: (params) => {
      if (params && params.value < 0.00) {
        return { color: 'red' };
      }
      return null;
    },
  };

  // const frameworkComponents = {
  //   ...imageCellRendererComponent,
  // };
  const tableConfig = {
    showDatePicker: false,
    showFooter: false,
    parentControlledLoad: true,
    apiEndPoint: `${config.serverUrl}/catalogs`,
    paginationPageSize: 5000,
    cacheBlockSize: 5000,
  };

  const datasource = {
    getRows: async (params) => {
      const {
        request, success, fail,
      } = params;
      const { startRow, sortModel } = request;
      const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
      const requestOptions = {
        method: 'GET',
        headers: headerWithAuth,
      };

      let sort = '';
      if (sortModel.length > 0) {
        sort = `${sortModel[0].colId} ${sortModel[0].sort}`;
      }

      const offset = startRow || 0;

      const accountId = selectedSeller.parentAccountId > 0 ? selectedSeller.parentAccountId : selectedSeller.value;

      const url = `${tableConfig.apiEndPoint}?accountId=${accountId}&currencyCode=${(selectedSeller.currencyCode || 'USD')}&accountTypeId=${selectedSeller.type}&limit=${tableConfig.paginationPageSize}&offset=${offset}&sort=${sort}`;
      try {
        const data = await doFetch(url, requestOptions);
        success({
          rowData: data,
          rowCount: data.length > 0 ? data[0].distinct_item_count : 0,
        })
      } catch (error) {
        fail();
      }
    },
  };

  function onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  const parentAccess = (agGridPointer) => {
    setAgGrid(agGridPointer);
  }

  useEffect(() => {
    if (agGrid) {
      agGrid.refreshData();
    }
  }, [forceRefresh]);

  const changeShowEditModal = () => {
    setShowEditModal(!showEditModal);
  };

  const selectionChanged = (selectdData) => {
    setSelectedData(selectdData);
  };

  return (
    <Container className="todo-app">
      <Row>
        <CardPanel
          title="Catalog"
          subhead={new Date().toISOString()}
          autorenewButton={false}
          closeButton={false}
        >
          <TableBuilder
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            apiEndPoint={tableConfig.apiEndPoint}
            showDatePicker={tableConfig.showDatePicker}
            showFooter={tableConfig.showFooter}
            // frameworkComponents={frameworkComponents}
            rowHeight={75}
            onFirstDataRendered={onFirstDataRendered}
            pagination
            rowModelType="serverSide"
            datasource={datasource}
            paginationPageSize={tableConfig.paginationPageSize}
            cacheBlockSize={tableConfig.cacheBlockSize}
            serverSideInfiniteScroll
            parentControlledLoad={tableConfig.parentControlledLoad}
            editSelectedRowsButton
            openEditSelected={changeShowEditModal}
            parentSelectionChanged={selectionChanged}
            parentAccess={parentAccess}
            exportPrefix={exportPrefix}
          />
        </CardPanel>
        <ItemEditModal
          changeShowEditModal={changeShowEditModal}
          showEditModal={showEditModal}
          theme={theme}
          selectedData={selectedData}
          setForceRefresh={setForceRefresh}
          budgetGroupOptions={budgetGroupOptions}
          setShowEditModal={setShowEditModal}
        />
      </Row>
    </Container>
  );
}

export default SimpleCatalogTable;
