import React, {
  useState, useEffect, useMemo, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
// import { AgSparklineOptions } from "ag-charts-community";
import { AgGridReact } from 'ag-grid-react';
import { ToggleWrapOuter, ToggleSlide } from './AgencyBudgetTableCss';
import GroupEditModal from './form/GroupEditModal';
// import Notify from '../../../../shared/components/widgets/Notify';
import config from '../../../../config';
import Panel from '../../../../shared/components/Panel';
// import Slider from '../../../../shared/components/range-slider/Slider';
import {
  currencyFormatter, formatDecimalToFixedTwoPercent, formatNumberOnePlaceDefaultToZero, formatNumberDefaultToZero, getExportPrefix, // formatDecimalToFixedTwoPercentDirect,
} from '../../../../shared/components/table/functions';
import { getPlatform } from '../../../../shared/components/domainSupport';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

export default function BudgetTable({
  allById, internalById, allByDate, internalByDate, retailer, title, allGraphLoad, internalGraphLoad, gridTheme, reportDays, forceRefresh, setForceRefresh, budgetAccountData, activeDayOptions, retailerConfig, setBudgetGroupOptions,
}) {
  const gridRef = useRef();
  const sellerSelect = useSelector(store => store.sellerSelect);
  const user = useSelector(store => store.authentication.user);
  const [combinedItems, setCombinedItems] = useState([]);
  const [budgetItems, setBudgetItems] = useState([]);
  const [panelLoad, setPanelLoad] = useState(false);
  const [hideNonEssentialBudget, setHideNonEssentialBudget] = useState(true);
  const [hideNonEssentialSpendControls, setHideNonEssentialSpendControls] = useState(false);
  const [hideNonEssentialMetrics, setHideNonEssentialMetrics] = useState(true);
  const [hideNonEssentialAdvancedMetrics, setHideNonEssentialAdvancedMetrics] = useState(false);
  const [hideNonEssentialSparklines, setHideNonEssentialSparklines] = useState(true);
  const [adminView, setAdminView] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editRowData, setEditRowData] = useState({});
  const [disableButton, setDisableButton] = useState(false);

  const platfromSpec = getPlatform();
  const unassignedText = '*UNASSIGNED*';

  const getData = () => {
    let retailerPath = '';
    if (retailer.toLowerCase() !== 'amazon') {
      retailerPath = `${retailer.toLowerCase()}/`;
    }
    const budgetUrl = `${apiUrl}/accounts/${retailerPath}budget-group-daily?accountId=${sellerSelect.value}&platform=${platfromSpec.nameKey}`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    setPanelLoad(true);
    setBudgetItems([]);

    fetch(budgetUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const budgetGroupOptions = [];
        data.budgetGroupData.forEach(bg => {
          budgetGroupOptions.push({ label: bg.budget_group_name, value: bg.budget_group_daily_id });
        });
        setPanelLoad(false);
        setBudgetItems(data.budgetGroupData);
        setBudgetGroupOptions(budgetGroupOptions);
      }).catch(() => {
        setPanelLoad(false);
      });
  };

  useEffect(() => {
    getData();
  }, [sellerSelect.value, forceRefresh]);

  // useEffect(() => {
  //   const newMoPacos = ((pacosNonBrandAvg / 100) + (pacosBrandAvg / 100)) / 2;
  //   setMonthlyPacos(newMoPacos || 0);
  // }, [pacosBrandAvg, pacosNonBrandAvg]);

  useEffect(() => {
    if (allById && internalById && allByDate && internalByDate) {
      const newBudgetItems = [];
      const getName = (currentName) => {
        if (currentName === '') {
          return unassignedText;
        }
        return currentName;
      }
      budgetItems?.forEach(budgetItemRow => {
        if (budgetItemRow.budget_group_name || budgetItemRow.budget_group_name === '') {
          const allSpendChange = [];
          const allSalesChange = [];
          const allAcosChange = [];
          const internalSpendChange = [];
          const internalSalesChange = [];
          const internalAcosChange = [];
          // Build Change Sparkline
          allByDate?.forEach(allDate => {
            allSpendChange.push(allDate[`spend_${budgetItemRow.budget_group_name}`]);
            allSalesChange.push(allDate[`sales_${budgetItemRow.budget_group_name}`]);
            allAcosChange.push((allDate[`spend_${budgetItemRow.budget_group_name}`] && allDate[`sales_${budgetItemRow.budget_group_name}`] && allDate[`sales_${budgetItemRow.budget_group_name}`] > 0) ? (allDate[`spend_${budgetItemRow.budget_group_name}`] / allDate[`sales_${budgetItemRow.budget_group_name}`]) : null);
          });
          internalByDate?.forEach(internalDate => {
            internalSpendChange.push(internalDate[`spend_${budgetItemRow.budget_group_name}`]);
            internalSalesChange.push(internalDate[`sales_${budgetItemRow.budget_group_name}`]);
            internalAcosChange.push((internalDate[`spend_${budgetItemRow.budget_group_name}`] && internalDate[`sales_${budgetItemRow.budget_group_name}`] && internalDate[`sales_${budgetItemRow.budget_group_name}`] > 0) ? (internalDate[`spend_${budgetItemRow.budget_group_name}`] / internalDate[`sales_${budgetItemRow.budget_group_name}`]) : null);
          });
          const allMetricRow = allById.group[budgetItemRow.budget_group_name];
          const internalMetricRow = internalById.group[budgetItemRow.budget_group_name];
          newBudgetItems.push({ ...budgetItemRow, name: getName(budgetItemRow.budget_group_name), ...allMetricRow, ...internalMetricRow, allSpendChange, allSalesChange, allAcosChange, internalSpendChange, internalSalesChange, internalAcosChange });
        }
      });
      setCombinedItems(newBudgetItems);
    }
  }, [allById, internalById, allByDate, internalByDate, budgetItems]);

  const onGridReady = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit({
      defaultMinWidth: 100,
    });
  }, []);

  const readableActiveDays = (activeDaysCsv) => {
    const readableArray = [];
    const activeDaysArray = activeDaysCsv?.split(',') || [];
    activeDaysArray.forEach((day, pos) => {
      if (day === '1') {
        readableArray.push(moment().isoWeekday(pos + 1).format('dddd'));
      }
    });
    return readableArray.join(', ');
  }

  // const updateBudgetGroupDropdown = (selectedOption, e, itemName) => {
  //   const budgetItemsUpdates = [...budgetItems]; // clone array
  //   const { name: groupName } = e;

  //   const objIndex = budgetItemsUpdates.findIndex((obj => obj.name === groupName));

  //   budgetItemsUpdates[objIndex][itemName] = selectedOption.value;
  //   setBudgetItems(budgetItemsUpdates);
  // }

  // const updateBudgetGroup = (e) => {
  //   const budgetItemsUpdates = [...budgetItems]; // clone array
  //   const { name, value, dataset } = e.target;
  //   const { groupName } = dataset;
  //   const objIndex = budgetItemsUpdates.findIndex((obj => obj.name === groupName));
  //   const itemName = name;
  //   const regex = name === 'portfolioIdsCsv' ? /[^\-0-9.,]/g : /[^\-0-9.]/g;
  //   budgetItemsUpdates[objIndex][itemName] = ['pacos', 'tacos', 'pacosBrand', 'pacosNonBrand'].includes(name) ? (value.replace(regex, '') / 100) : value.replace(regex, '');
  //   setBudgetItems(budgetItemsUpdates);
  // }

  const budgetStateOptions = [
    { label: 'Enabled', value: 'enabled' },
    { label: 'Disabled', value: 'disabled' },
    { label: 'Draft', value: 'draft' },
    { label: 'Completed', value: 'completed' },
  ];

  const limitlessBudgetOptions = [
    { label: 'Strict Budget', value: 0 },
    { label: 'No Budget', value: 1 },
    { label: 'Flexible Budget', value: 2 },
  ];

  const includesOptions = [
    { label: 'All ADs', value: '' },
    { label: 'Limitless ADs', value: 'limitless' },
  ];

  const spendPriorityOptions = [
    { label: 'Most Brand', value: '-2' },
    { label: 'More Brand', value: '-1' },
    { label: 'Brand and NonBrand', value: '0' },
    { label: 'More NonBrand', value: '1' },
    { label: 'Most NonBrand', value: '2' },
  ];

  const spendTacticOptions = [
    { label: 'Max Performance', value: 'max_perf' },
    { label: 'Full Budget Spend', value: 'full_spend' },
  ];

  const spendSpeedOptions = [
    { label: 'Standard', value: 'speed_level_1' },
    { label: 'Fast', value: 'speed_level_2' },
    { label: 'Fastest', value: 'speed_level_3' },
  ];

  const setupFlowOptions = [
    { label: 'Quick Setup', value: 'quick' },
    { label: 'Advanced Setup', value: 'advanced' },
  ];

  const agencyInternalOptions = [
    { label: 'Agency View', value: false },
    { label: 'Limitless View', value: true },
  ];

  const budgetDriverOptions = [
    { label: 'Daily', value: 'daily' },
    { label: 'Monthly', value: 'monthly' },
  ];

  const costTargetTypeOptions = [
    { label: 'Blended', value: 'blended' },
    { label: 'Brand/NonBrand', value: 'bnb' },
  ];

  // const findOption = (value, options) => {
  //   const foundObject = options.find(item => item.value === value);
  //   if (foundObject) {
  //     return foundObject;
  //   }
  //   return options[0];
  // };

  // function extractValues(mappings) {
  //   return mappings.map(map => map.value);
  // }

  function lookupValue(mappings, valueKey) {
    return mappings.find(map => map.value === valueKey);
  }

  const aggFuncs = useMemo(() => {
    const aggFuncsReturn = {
      customAvg: params => {
        let count = 0;
        let total = 0;
        params.values.forEach(value => {
          // if (value > 0) {
          count += 1;
          total += value;
          // }
        });
        const returnValue = count > 0 ? (total / count) : 0;
        return returnValue.toFixed(1);
      },
    };
    return aggFuncsReturn;
  }, []);

  const changeShowEditModal = (data) => {
    setEditRowData(data);
    setShowEditModal(!showEditModal);
  };

  const countActiveDaysInMonth = (activeDaysCsv, startDate, endDate) => {
    let count = 0;
    const today = moment();
    const daysInMonth = today.daysInMonth();
    for (let day = 1; day <= daysInMonth; day += 1) {
      const checkDay = moment([today.year(), today.month(), day]);
      const dayOfWeekNum = checkDay.isoWeekday() - 1;
      if (activeDaysCsv.split(',')[dayOfWeekNum] === '1' && checkDay >= moment(startDate) && (checkDay <= moment(endDate) || endDate === null)) {
        count += 1;
      }
    }
    return count;
  }

  const addNewDefault = {
    setupFlow: setupFlowOptions[0],
    agencyInternal: agencyInternalOptions[0],
    budgetDriver: budgetDriverOptions[0],
    costTargetType: costTargetTypeOptions[0],
  }

  // AG Grid variables
  /* consider these as well (for reference)
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  */
  const columnDefs = [
    {
      field: 'editBg',
      headerName: 'BG',
      editable: false,
      cellRenderer: (params) => {
        if (params.node?.footer) {
          return '';
        }
        return <button type="submit" onClick={() => changeShowEditModal(params.data)}>Edit Row</button>;
      },
    },
    // {
    //   field: 'editItems',
    //   headerName: 'Items',
    //   editable: false,
    //   cellRenderer: (params) => {
    //     if (params.node?.footer) {
    //       return '';
    //     }
    //     return <button type="submit" onClick={() => changeShowEditModal(params.data)}>Edit Items</button>;
    //   },
    // },
    {
      field: 'budget_group_daily_id',
      headerName: 'Budget Group Daily ID (hide later)',
      editable: false,
      hide: true,
      suppressColumnsToolPanel: !adminView,
    },
    {
      field: 'calcState',
      headerName: 'State',
      editable: false,
      valueGetter: params => {
        let currentState = 'draft';
        if (params.node?.footer) {
          return '';
        }
        if (params.data?.state) {
          currentState = params.data.end_date && moment(params.data.end_date).format('YYYYMMDD') < moment().format('YYYYMMDD') && (params.data.state === 'enabled' || params.data.state === 'disabled') ? 'completed' : params.data.state;
        }
        const dispalyObj = lookupValue(budgetStateOptions, currentState);
        return dispalyObj?.label || '';
      },
    },
    {
      field: 'budget_group_name',
      headerName: 'Budget Group',
      editable: false,
      valueFormatter: (params) => {
        if (params.node?.footer) {
          return 'TOTAL/AVG';
        }
        return params.value;
      },
    },
    {
      headerName: 'Budgets',
      children: [
        {
          field: 'start_date',
          headerName: 'Start',
          valueFormatter: (params) => {
            if (params.node?.footer) {
              return '';
            }
            return params.value;
          },
        },
        {
          field: 'end_date',
          headerName: 'End',
          valueFormatter: (params) => {
            if (params.node?.footer) {
              return '';
            }
            return params.value;
          },
        },
        {
          field: 'budget_driver',
          headerName: 'Driver',
          valueFormatter: (params) => {
            if (params.node?.footer) {
              return '';
            }
            if (params.value) {
              return params.value.charAt(0).toUpperCase() + params.value.slice(1);
            }
            return params.value;
          },
        },
        {
          field: 'calcDaily',
          headerName: 'Daily',
          aggFunc: 'sum',
          valueGetter: params => {
            let dailyBudget = 0;
            if (params.data?.budget_driver === 'daily') {
              dailyBudget = parseFloat(JSON.parse(params.data.budget_json).daily.budget);
            } else if (params.data?.budget_driver === 'monthly') {
              const activeDaysInMonth = countActiveDaysInMonth(params.data.active_days_csv, params.data.start_date, params.data.end_date);
              if (activeDaysInMonth > 0) {
                dailyBudget = parseFloat(JSON.parse(params.data.budget_json).monthly[moment().format('M').toString()].budget / activeDaysInMonth);
              }
            }
            return dailyBudget;
          },
          valueFormatter: (params) => currencyFormatter(params),
        },
        {
          field: 'calcMonthly',
          headerName: 'Monthly',
          aggFunc: 'sum',
          valueGetter: (params) => {
            let currentMonthBudget = 0;
            if (params.data?.budget_driver === 'daily') {
              const activeDaysInMonth = countActiveDaysInMonth(params.data.active_days_csv, params.data.start_date, params.data.end_date);
              currentMonthBudget = parseFloat(JSON.parse(params.data.budget_json).daily.budget * activeDaysInMonth);
            } else if (params.data?.budget_driver === 'monthly') {
              currentMonthBudget = parseFloat(JSON.parse(params.data.budget_json).monthly[moment().format('M').toString()].budget);
            }
            return currentMonthBudget;
          },
          valueFormatter: (params) => currencyFormatter(params),
        },
        {
          field: 'calcActiveDays',
          headerName: 'Active Days',
          hide: !hideNonEssentialBudget,
          valueGetter: params => {
            if (params.node?.footer) {
              return '';
            }
            if (params.data?.active_days_csv) {
              return readableActiveDays(params.data.active_days_csv);
            }
            return 0;
          },
        },
        {
          field: 'calcActiveDaysInWeek',
          headerName: 'Active Days this Week',
          hide: !hideNonEssentialBudget,
          valueGetter: params => {
            if (params.node?.footer) {
              return '';
            }
            if (params.data?.active_days_csv) {
              return params.data.active_days_csv.match(/1/g).length;
            }
            return 0;
          },
        },
        {
          field: 'calcActiveDaysInMonth',
          headerName: 'Active Days this Month',
          hide: !hideNonEssentialBudget,
          valueGetter: params => {
            if (params.node?.footer) {
              return '';
            }
            if (params.data?.active_days_csv) {
              return countActiveDaysInMonth(params.data.active_days_csv, params.data.start_date, params.data.end_date);
            }
            return 0;
          },
        },
        {
          field: 'calcDaysInMonth',
          headerName: 'Days this Month',
          hide: !hideNonEssentialBudget,
          valueGetter: params => {
            if (params.node?.footer) {
              return '';
            }
            return moment().daysInMonth()
          },
        },
        {
          field: 'active_events',
          headerName: 'Active Events',
          hide: !hideNonEssentialBudget,

        },
        {
          field: 'upcoming_events',
          headerName: 'Upcoming Events',
          hide: !hideNonEssentialBudget,
        },
        {
          field: 'past_events',
          headerName: 'Past Events',
          hide: !hideNonEssentialBudget,
        },
        {
          field: 'reallocation',
          headerName: 'Reallocation',
          hide: true,
          suppressColumnsToolPanel: !adminView,
          aggFunc: 'sum',
          valueFormatter: params => currencyFormatter(params),
          valueGetter: (params) => parseFloat(JSON.parse(params.data.budget_json).daily?.reallocation || 0),
        },
      ],
    },
    {
      headerName: 'Cost Targets',
      children: [
        {
          field: 'cost_target_type',
          headerName: 'Cost Target Type',
          valueFormatter: (params) => {
            if (params.value === 'bnb') {
              return 'BNB';
            }
            if (params.value === 'blended') {
              return 'Blended';
            }
            return params.value;
          },
        },
        {
          field: 'cost_target_brand',
          headerName: 'Brand Cost Target',
          valueFormatter: (params) => {
            if (params.node?.footer) {
              return '';
            }
            if (params.data?.cost_target_metric === 'pacos') {
              return params.value.toString().concat('% PACoS');
            }
            if (params.data?.cost_target_metric === 'roas') {
              return params.value.toString().concat(' ROAS');
            }
            return params.value;
          },
          valueGetter: params => {
            if (params.node?.footer) {
              return '';
            }
            return (params.data?.cost_target_brand || 0);
          },
        },
        {
          field: 'cost_target_nonbrand',
          headerName: 'Non-Brand Cost Target',
          valueFormatter: (params) => {
            if (params.node?.footer) {
              return '';
            }
            if (params.data?.cost_target_metric === 'pacos') {
              return params.value.toString().concat('% PACoS');
            }
            if (params.data?.cost_target_metric === 'roas') {
              return params.value.toString().concat(' ROAS');
            }
            return params.value;
          },
          valueGetter: params => {
            if (params.node?.footer) {
              return '';
            }
            return (params.data?.cost_target_nonbrand || 0);
          },
        },
      ],
    },
    {
      headerName: 'Spend Controls',
      children: [
        {
          field: 'portfolio_ids_csv',
          headerName: 'Portfolio ID',
          hide: !hideNonEssentialSpendControls,
          suppressColumnsToolPanel: !adminView,
          valueGetter: params => (params.data?.portfolio_ids_csv || '').replace(/[^\-0-9.,]/g, ''),
        },
        {
          field: 'limitless_budget',
          headerName: 'Budget Type',
          hide: !adminView,
          suppressColumnsToolPanel: !adminView,
          valueGetter: params => {
            if (params.node?.footer) {
              return '';
            }
            const dispalyObj = lookupValue(limitlessBudgetOptions, params.value || 0);
            return dispalyObj?.label || '';
          },
        },
        {
          field: 'includes',
          headerName: 'Includes Ads',
          hide: !hideNonEssentialSpendControls,
          suppressColumnsToolPanel: !adminView,
          valueGetter: params => {
            if (params.node?.footer) {
              return '';
            }
            const dispalyObj = lookupValue(includesOptions, params.value || 'limitless');
            return dispalyObj?.label || '';
          },
        },
        {
          field: 'spendPriority',
          headerName: 'Spend Priority',
          valueGetter: params => {
            if (params.node?.footer) {
              return '';
            }
            const dispalyObj = lookupValue(spendPriorityOptions, params.value || '0');
            return dispalyObj?.label || '';
          },
        },
        {
          field: 'spendTactic',
          headerName: 'Spend Tactic',
          hide: !hideNonEssentialSpendControls,
          suppressColumnsToolPanel: !adminView,
          valueGetter: params => {
            if (params.node?.footer) {
              return '';
            }
            const dispalyObj = lookupValue(spendTacticOptions, params.value || 'max_perf');
            return dispalyObj?.label || '';
          },
        },
        {
          field: 'spendSpeed',
          headerName: 'Spend Speed',
          hide: !hideNonEssentialSpendControls,
          suppressColumnsToolPanel: !adminView,
          valueGetter: params => {
            if (params.node?.footer) {
              return '';
            }
            const dispalyObj = lookupValue(spendSpeedOptions, params.value || 'speed_level_1');
            return dispalyObj?.label || '';
          },
        },
      ],
    },
    {
      headerName: 'Metrics',
      children: [
        {
          field: 'internal_spend',
          headerName: 'MTD Limitless PPC Spend',
          hide: !hideNonEssentialMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
        {
          field: 'internal_sales',
          headerName: 'MTD Limitless PPC Sales',
          hide: !hideNonEssentialMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
        {
          field: '',
          headerName: 'MTD Limitless PPC ACoS',
          hide: !hideNonEssentialMetrics,
          editable: false,
          aggFunc: (params) => {
            if (params && params.rowNode && params.rowNode.aggData && params.rowNode.aggData.internal_spend && params.rowNode.aggData.internal_sales && params.rowNode.aggData.internal_sales !== 0) {
              return params.rowNode.aggData.internal_spend / params.rowNode.aggData.internal_sales;
            }
            return 0;
          },
          valueFormatter: formatDecimalToFixedTwoPercent,
          valueGetter: function calcValueGetter(params) {
            if (params && params.data && params.data.internal_spend && params.data.internal_sales && params.data.internal_sales !== 0) {
              return params.data.internal_spend / params.data.internal_sales;
            }
            return 0;
          },
          headerTooltip: 'MTD Limitless PPC ACoS',
        },
        {
          field: '',
          headerName: 'MTD Limitless PPC ROAS',
          hide: !hideNonEssentialMetrics,
          editable: false,
          aggFunc: (params) => {
            if (params && params.rowNode && params.rowNode.aggData && params.rowNode.aggData.internal_spend && params.rowNode.aggData.internal_sales && params.rowNode.aggData.internal_spend !== 0) {
              return params.rowNode.aggData.internal_sales / params.rowNode.aggData.internal_spend;
            }
            return 0;
          },
          valueFormatter: formatNumberOnePlaceDefaultToZero,
          valueGetter: function calcValueGetter(params) {
            if (params && params.data && params.data.internal_spend && params.data.internal_sales && params.data.internal_spend !== 0) {
              return params.data.internal_sales / params.data.internal_spend;
            }
            return 0;
          },
          headerTooltip: 'MTD Limitless PPC ROAS',
        },
        // {
        //   field: 'internal_orders',
        //   headerName: 'MTD Limitless Orders',
        //   hide: true,
        //   editable: false,
        //   aggFunc: 'sum',
        //   valueFormatter: formatNumberDefaultToZero,
        // },
        // {
        //   field: 'all_spend',
        //   headerName: 'MTD All PPC Spend',
        //   hide: !hideNonEssentialMetrics,
        //   editable: false,
        //   aggFunc: 'sum',
        //   valueFormatter: currencyFormatter,
        // },
        // {
        //   field: 'all_sales',
        //   headerName: 'MTD All PPC Sales',
        //   hide: !hideNonEssentialMetrics,
        //   editable: false,
        //   aggFunc: 'sum',
        //   valueFormatter: currencyFormatter,
        // },
        // {
        //   field: '',
        //   headerName: 'MTD All PPC ACoS',
        //   hide: !hideNonEssentialMetrics,
        //   editable: false,
        //   aggFunc: (params) => {
        //     if (params && params.rowNode && params.rowNode.aggData && params.rowNode.aggData.all_spend && params.rowNode.aggData.all_sales && params.rowNode.aggData.all_sales !== 0) {
        //       return params.rowNode.aggData.all_spend / params.rowNode.aggData.all_sales;
        //     }
        //     return 0;
        //   },
        //   valueFormatter: formatDecimalToFixedTwoPercent,
        //   valueGetter: function calcValueGetter(params) {
        //     if (params && params.data && params.data.all_spend && params.data.all_sales && params.data.all_sales !== 0) {
        //       return params.data.all_spend / params.data.all_sales;
        //     }
        //     return 0;
        //   },
        //   headerTooltip: 'MTD All PPC ACoS',
        // },
        // {
        //   field: '',
        //   headerName: 'MTD All PPC ROAS',
        //   hide: !hideNonEssentialMetrics,
        //   editable: false,
        //   aggFunc: (params) => {
        //     if (params && params.rowNode && params.rowNode.aggData && params.rowNode.aggData.all_spend && params.rowNode.aggData.all_sales && params.rowNode.aggData.all_spend !== 0) {
        //       return params.rowNode.aggData.all_sales / params.rowNode.aggData.all_spend;
        //     }
        //     return 0;
        //   },
        //   valueFormatter: formatNumberOnePlaceDefaultToZero,
        //   valueGetter: function calcValueGetter(params) {
        //     if (params && params.data && params.data.all_spend && params.data.all_sales && params.data.all_spend !== 0) {
        //       return params.data.all_sales / params.data.all_spend;
        //     }
        //     return 0;
        //   },
        //   headerTooltip: 'MTD All PPC ROAS',
        // },
        // {
        //   field: 'all_orders',
        //   headerName: 'MTD All Orders',
        //   hide: true,
        //   editable: false,
        //   aggFunc: 'sum',
        //   valueFormatter: formatNumberDefaultToZero,
        // },
        {
          field: '',
          headerName: 'MTD Consumption Rate',
          hide: !hideNonEssentialMetrics,
          editable: false,
          valueFormatter: (params) => {
            if (params.node?.footer) {
              return '';
            }
            return formatDecimalToFixedTwoPercent(params);
          },
          valueGetter: function calcValueGetter(params) {
            if (params.node?.footer) {
              return '';
            }
            const totalBudget = (params.data.current || 0) + (params.data.reallocation || 0) + (params.data.carryover || 0) + (params.data.supplemental || 0);
            let calcBudget = totalBudget; // limitlessBudgetFlag === 0
            const daysInMonth = moment().daysInMonth();
            if (params.data.limitlessBudgetFlag === 1) {
              calcBudget = totalBudget * 10;
            }
            if (params.data.limitlessBudgetFlag === 2) {
              calcBudget = totalBudget * 1.25;
            }

            if (params && params.data && params.data.internal_spend && reportDays.internalReportDays !== 0 && calcBudget && calcBudget !== 0 && daysInMonth !== 0 && params.data.includes === 'limitless') {
              return (params.data.internal_spend / reportDays.internalReportDays) / (calcBudget / daysInMonth);
            }
            if (params && params.data && params.data.all_spend && reportDays.allReportDays !== 0 && calcBudget && calcBudget !== 0 && daysInMonth !== 0) {
              return (params.data.all_spend / reportDays.allReportDays) / (calcBudget / daysInMonth);
            }
            return 0;
          },
          headerTooltip: 'Allocation',
        },
        {
          field: 'itemOnCount',
          headerName: 'Items ON in BG',
          hide: !hideNonEssentialMetrics,
          editable: false,
          aggFunc: 'sum',
        },
        // {
        //   field: 'calcBgDailyDollarPerItem',
        //   headerName: 'BG Daily $/Item',
        //   hide: !hideNonEssentialMetrics,
        //   editable: false,
        //   valueFormatter: (params) => {
        //     if (params.node?.footer) {
        //       return '';
        //     }
        //     return currencyFormatter(params);
        //   },
        //   valueGetter: function calcValueGetter(params) {
        //     const daysInMonth = moment().daysInMonth();
        //     if (params && params.data && params.data.itemOnCount && params.data.itemOnCount !== 0 && daysInMonth !== 0) {
        //       const totalBudget = (params.data.current || 0) + (params.data.reallocation || 0) + (params.data.carryover || 0) + (params.data.supplemental || 0);
        //       return (totalBudget / daysInMonth) / params.data.itemOnCount;
        //     }
        //     return 0;
        //   },
        // },
        {
          field: 'calcLlMtdDailyDollarPerItem',
          headerName: 'LL MTD Daily $/Item',
          hide: !hideNonEssentialMetrics,
          editable: false,
          valueFormatter: (params) => {
            if (params.node?.footer) {
              return '';
            }
            return currencyFormatter(params);
          },
          valueGetter: function calcValueGetter(params) {
            if (params && params.data && params.data.internal_spend && params.data.itemOnCount && params.data.itemOnCount !== 0 && reportDays.internalReportDays !== 0) {
              return (params.data.internal_spend / reportDays.internalReportDays) / params.data.itemOnCount;
            }
            return 0;
          },
        },
        // {
        //   field: 'calcAllMtdDailyDollarPerItem',
        //   headerName: 'All MTD Daily $/Item',
        //   hide: !hideNonEssentialMetrics,
        //   editable: false,
        //   valueFormatter: (params) => {
        //     if (params.node?.footer) {
        //       return '';
        //     }
        //     return currencyFormatter(params);
        //   },
        //   valueGetter: function calcValueGetter(params) {
        //     if (params && params.data && params.data.all_spend && params.data.itemOnCount && params.data.itemOnCount !== 0 && reportDays.internalReportDays !== 0) {
        //       return (params.data.all_spend / reportDays.internalReportDays) / params.data.itemOnCount;
        //     }
        //     return 0;
        //   },
        // },
      ],
    },
    {
      headerName: 'Advanced Metrics',
      suppressColumnsToolPanel: !adminView,
      children: [
        {
          field: 'internal_advertised_sku_sales',
          headerName: 'MTD Limitless Advertised Sku Sales',
          hide: !hideNonEssentialAdvancedMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
        {
          field: 'internal_brand_sales',
          headerName: 'MTD Limitless Brand Sales',
          hide: !hideNonEssentialAdvancedMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
        {
          field: 'internal_direct_sales',
          headerName: 'MTD Limitless Direct Sales',
          hide: !hideNonEssentialAdvancedMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
        {
          field: 'internal_related_sales',
          headerName: 'MTD Limitless Related Sales',
          hide: !hideNonEssentialAdvancedMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
        {
          field: 'internal_ntb_orders',
          headerName: 'MTD Limitless NTB Orders',
          hide: !adminView,
          editable: false,
          aggFunc: 'sum',
          valueFormatter: formatNumberDefaultToZero,
        },
        {
          field: 'internal_ntb_revenue',
          headerName: 'MTD Limitless NTB Revenue',
          hide: !hideNonEssentialAdvancedMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
        {
          field: 'internal_other_sku_sales',
          headerName: 'MTD Limitless Other SKU Sales',
          hide: !hideNonEssentialAdvancedMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
        {
          field: 'internal_in_store_orders',
          headerName: 'MTD Limitless In Store Orders',
          hide: !adminView,
          editable: false,
          aggFunc: 'sum',
          valueFormatter: formatNumberDefaultToZero,
        },
        {
          field: 'internal_in_store_sales',
          headerName: 'MTD Limitless In Store Sales',
          hide: !hideNonEssentialAdvancedMetrics,
          aggFunc: 'sum',
          editable: false,
          valueFormatter: currencyFormatter,
        },
        // {
        //   field: 'all_advertised_sku_sales',
        //   headerName: 'MTD ALL Advertised Sku Sales',
        //   hide: !hideNonEssentialAdvancedMetrics,
        //   aggFunc: 'sum',
        //   editable: false,
        //   valueFormatter: currencyFormatter,
        // },
        // {
        //   field: 'all_brand_sales',
        //   headerName: 'MTD ALL Brand Sales',
        //   hide: !hideNonEssentialAdvancedMetrics,
        //   aggFunc: 'sum',
        //   editable: false,
        //   valueFormatter: currencyFormatter,
        // },
        // {
        //   field: 'all_direct_sales',
        //   headerName: 'MTD ALL Direct Sales',
        //   hide: !hideNonEssentialAdvancedMetrics,
        //   aggFunc: 'sum',
        //   editable: false,
        //   valueFormatter: currencyFormatter,
        // },
        // {
        //   field: 'all_related_sales',
        //   headerName: 'MTD ALL Related Sales',
        //   hide: !hideNonEssentialAdvancedMetrics,
        //   aggFunc: 'sum',
        //   editable: false,
        //   valueFormatter: currencyFormatter,
        // },
        // {
        //   field: 'all_ntb_orders',
        //   headerName: 'MTD ALL NTB Orders',
        //   hide: true,
        //   editable: false,
        //   aggFunc: 'sum',
        //   valueFormatter: formatNumberDefaultToZero,
        // },
        // {
        //   field: 'all_ntb_revenue',
        //   headerName: 'MTD ALL NTB Revenue',
        //   hide: !hideNonEssentialAdvancedMetrics,
        //   aggFunc: 'sum',
        //   editable: false,
        //   valueFormatter: currencyFormatter,
        // },
        // {
        //   field: 'all_other_sku_sales',
        //   headerName: 'MTD ALL Other SKU Sales',
        //   hide: !hideNonEssentialAdvancedMetrics,
        //   aggFunc: 'sum',
        //   editable: false,
        //   valueFormatter: currencyFormatter,
        // },
        // {
        //   field: 'all_in_store_orders',
        //   headerName: 'MTD ALL In Store Orders',
        //   hide: true,
        //   editable: false,
        //   aggFunc: 'sum',
        //   valueFormatter: formatNumberDefaultToZero,
        // },
        // {
        //   field: 'all_in_store_sales',
        //   headerName: 'MTD ALL In Store Sales',
        //   hide: !hideNonEssentialAdvancedMetrics,
        //   aggFunc: 'sum',
        //   editable: false,
        //   valueFormatter: currencyFormatter,
        // },
      ],
    },
    {
      headerName: 'Sparklines',
      children: [
        {
          field: 'internalSpendChange',
          headerName: 'Limitless Spend Change',
          hide: !hideNonEssentialSparklines,
          cellRenderer: 'agSparklineCellRenderer',
          cellRendererParams: {
            sparklineOptions: {
              type: 'area',
              fill: 'rgba(70, 133, 244, 0.4)',
              stroke: 'rgb(0, 0, 255)',
              marker: {
                enabled: true,
                size: 0,
                itemStyler: (params) => {
                  if (params.highlighted) {
                    return {
                      size: 7,
                    };
                  }
                  return { size: 7 }
                },
              },
              highlightStyle: {
                item: {
                  fill: 'rgb(143,185,77)',
                  strokeWidth: 0,
                },
              },
              axis: {
                type: 'category',
                stroke: 'rgb(204, 204, 235)',
              },
            },
          },
        },
        {
          field: 'internalSalesChange',
          headerName: 'Limitless Sales Change',
          hide: !hideNonEssentialSparklines,
          cellRenderer: 'agSparklineCellRenderer',
          cellRendererParams: {
            sparklineOptions: {
              type: 'area',
              fill: 'rgba(70, 133, 244, 0.4)',
              stroke: 'rgb(0, 0, 255)',
              marker: {
                enabled: true,
                size: 0,
                itemStyler: (params) => {
                  if (params.highlighted) {
                    return {
                      size: 7,
                    };
                  }
                  return { size: 7 }
                },
              },
              highlightStyle: {
                item: {
                  fill: 'rgb(143,185,77)',
                  strokeWidth: 0,
                },
              },
              axis: {
                type: 'category',
                stroke: 'rgb(204, 204, 235)',
              },
            },
          },
        },
        {
          field: 'internalAcosChange',
          headerName: 'Limitless ACoS Change',
          hide: !hideNonEssentialSparklines,
          cellRenderer: 'agSparklineCellRenderer',
          cellRendererParams: {
            sparklineOptions: {
              type: 'area',
              fill: 'rgba(70, 133, 244, 0.4)',
              stroke: 'rgb(0, 0, 255)',
              marker: {
                enabled: true,
                size: 0,
                itemStyler: (params) => {
                  if (params.highlighted) {
                    return {
                      size: 7,
                    };
                  }
                  return { size: 7 }
                },
              },
              highlightStyle: {
                item: {
                  fill: 'rgb(143,185,77)',
                  strokeWidth: 0,
                },
              },
              axis: {
                type: 'category',
                stroke: 'rgb(204, 204, 235)',
              },
            },
          },
        },
        // {
        //   field: 'allSpendChange',
        //   headerName: 'All Spend Change',
        //   hide: !hideNonEssentialSparklines,
        //   cellRenderer: 'agSparklineCellRenderer',
        // },
        // {
        //   field: 'allSalesChange',
        //   headerName: 'All Sales Change',
        //   hide: !hideNonEssentialSparklines,
        //   cellRenderer: 'agSparklineCellRenderer',
        // },
        // {
        //   field: 'allAcosChange',
        //   headerName: 'All ACoS Change',
        //   hide: !hideNonEssentialSparklines,
        //   cellRenderer: 'agSparklineCellRenderer',
        // },
      ],
    },
  ];

  const onBtnExport = () => {
    const exportPrefix = getExportPrefix(`${retailerConfig.retailerPrefix}-daily-budget-groups`, sellerSelect, true);
    const fileName = `${exportPrefix}.csv`;
    gridRef.current.api.exportDataAsCsv({ fileName });
  };

  const defaultColDef = {
    editable: false,
    sortable: true,
    filter: true,
    resizable: true,
  }

  return (
    <>
      <div style={{ height: '100%', width: '100%' }}>
        <GroupEditModal
          showEditModal={showEditModal}
          currentEditRow={editRowData}
          changeShowEditModal={changeShowEditModal}
          setForceRefresh={setForceRefresh}
          budgetAccountData={budgetAccountData}
          activeDayOptions={activeDayOptions}
          disableButton={disableButton}
          setDisableButton={setDisableButton}
          setShowEditModal={setShowEditModal}
        />
        <Panel
          lg={12}
          md={12}
          title={`${title} Group Settings`}
          subhead="Used to manage budgets at a 'budget group' level"
          parentRefresh={panelLoad || allGraphLoad || internalGraphLoad}
          getData={() => getData()}
        >
          <div
            className={gridTheme}
            style={{ width: '100%' }}
          >
            <br />
            <div className="topbar__wrapper">
              <div>
                Show Additional Columns:
                <ToggleWrapOuter>
                  <ToggleSlide
                    name="Budgets"
                    setFunction={() => setHideNonEssentialBudget(!hideNonEssentialBudget)}
                    currentState={hideNonEssentialBudget}
                    bgColor={platfromSpec.colorPrimary}
                  />
                  {user && user.access === 'admin' && adminView && (
                    <ToggleSlide
                      name="Spend Controls"
                      setFunction={() => setHideNonEssentialSpendControls(!hideNonEssentialSpendControls)}
                      currentState={hideNonEssentialSpendControls}
                      bgColor={platfromSpec.colorPrimary}
                    />
                  )}
                  <ToggleSlide
                    name="Metrics"
                    setFunction={() => setHideNonEssentialMetrics(!hideNonEssentialMetrics)}
                    currentState={hideNonEssentialMetrics}
                    bgColor={platfromSpec.colorPrimary}
                  />
                  {user && user.access === 'admin' && adminView && (
                    <ToggleSlide
                      name="Advanced Metrics"
                      setFunction={() => setHideNonEssentialAdvancedMetrics(!hideNonEssentialAdvancedMetrics)}
                      currentState={hideNonEssentialAdvancedMetrics}
                      bgColor={platfromSpec.colorPrimary}
                    />
                  )}
                  <ToggleSlide
                    name="Sparklines"
                    setFunction={() => setHideNonEssentialSparklines(!hideNonEssentialSparklines)}
                    currentState={hideNonEssentialSparklines}
                    bgColor={platfromSpec.colorPrimary}
                  />
                  {user && user.access === 'admin' && (
                    <ToggleSlide
                      name="AdminView"
                      setFunction={() => setAdminView(!adminView)}
                      currentState={adminView}
                      bgColor={platfromSpec.colorPrimary}
                    />
                  )}
                </ToggleWrapOuter>
              </div>
              <div className="topbar__right">
                <div className="form__button-toolbar">
                  <button className="btn btn-primary" onClick={() => changeShowEditModal(addNewDefault)} type="button">Add Budget Group</button>
                  <button className="btn btn-primary" onClick={onBtnExport} type="button">Export</button>
                </div>
              </div>
            </div>
            <br />
            <div className={gridTheme} style={{ height: '50vh', width: '100%' }}>
              <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={combinedItems}
                defaultColDef={defaultColDef}
                groupIncludeTotalFooter
                aggFuncs={aggFuncs}
                onGridReady={onGridReady}
                suppressScrollOnNewData
                enableRangeSelection
                rowSelection={'multiple'}
              />
            </div>
          </div>
        </Panel>
      </div>
    </>
  );
}

BudgetTable.propTypes = {
  gridTheme: PropTypes.string,
  internalGraphLoad: PropTypes.bool.isRequired,
  allGraphLoad: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  retailer: PropTypes.string.isRequired,
  internalById: PropTypes.shape().isRequired,
  allById: PropTypes.shape().isRequired,
};

BudgetTable.defaultProps = {
  gridTheme: 'ag-theme-balham',
};
