import React from 'react';
// import { useSelector } from 'react-redux';
import { Container, Row } from 'reactstrap';
import BudgetAccountEditForm from './form/BudgetAccountEditForm';
import CardPanel from '../../../../shared/components/widgets/CardPanel';
// import config from '../../../../config';
// import TableBuilder from '../../../../shared/components/table/TableBuilder';
// import { currencyFormatter, currencyFormatterWhole, formatDecimalToFixedTwoPercent, getExportPrefix } from '../../../../shared/components/table/functions';

export default function BudgetEventsTable({ budgetAccountData, setForceRefresh }) {
  // const sellerSelect = useSelector(store => store.sellerSelect);

  return (
    <Container>
      <CardPanel
        showButtons={false}
      >
        <Row>
          <BudgetAccountEditForm budgetAccountData={budgetAccountData} setForceRefresh={setForceRefresh} />
        </Row>
      </CardPanel>
    </Container>
  );
}
